<template>
  <div class="popup__bg">
    <div class="popup popup--iframe w-1040">
      <div class="popup__container popup__container--iframe">
        <div class="popup__interactive popup__interactive--iframe"><div class="ny-static"><iframe src="https://facecast.net/w/zntn6t" width="976" height="549" style="border:none" allow="autoplay; fullscreen" allowfullscreen></iframe></div></div>
        <div class="popup__article popup__article--iframe" data-lenis-prevent>
          <div class="popup__article-content popup__ny">
            <div
              class="popup__title popup__title--mini"
              v-html="activeContent.title"
              :class="{
                'popup__title--small-mobile': activeContent.small_title_mobile,
                'popup__title--mini-mobile': activeContent.mini_title_mobile,
              }"
            ></div>
            <div class="popup__subtitle" v-html="activeContent.info"></div>
            <div class="popup__text popup__text--ny" v-html="activeContent.text"></div>
          </div>
        </div>
        <div class="popup__close" @click="$emit('close')"><span></span></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Popup_3",
  props: {
    activeContent: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="sass">
@import '@/assets/sass/functions.sass'
@import '@/assets/sass/popup.sass'
.popup--iframe
	@media screen and (min-width: 1000px)
		display: grid
.ny-static
	background-image: url(../assets/img/ny-poster.jpg)
	width: 100%
	height: 100%
	background-size: contain
	iframe
		width: 100%
		height: 100%
	@media screen and (max-width: 1000px)
		background-repeat: no-repeat
		background-size: cover
@media screen and (max-width: 1000px)
	.popup__interactive--iframe
		height: 95vw !important
@media screen and (min-width: 1000px)
	.popup__container--iframe
		margin-top: auto
		margin-bottom: auto
		height: 24.6vw !important
	.popup__interactive--iframe
		height: 24.6vw !important
	.popup__article--iframe
		height: 24.6vw  !important
		.popup__text
			font-size: 0.9vw
			line-height: 1.2
.popup__text--ny
	ul
		color: #FFB49A
		margin-bottom: vw(10px)
		@media screen and (max-width: 1000px)
			margin-bottom: vwm(20px)
	li
		padding-left: vw(20px)
		margin-bottom: vw(6px)
		position: relative
		@media screen and (max-width: 1000px)
			padding-left: vwm(13px)
			margin-bottom: vwm(10px)
		&:before
			content: ''
			position: absolute
			left: 0
			top: 0.4vw
			width: vw(4px)
			min-width: vw(4px)
			height: vw(4px)
			border-radius: 50%
			background-color: #FFB49A
			@media screen and (max-width: 1000px)
				width: vwm(4px)
				height: vwm(4px)
				top: 0.8vw
.higlighted-subtitle
	color: #FFB49A
.popup__ny
	.popup__subtitle
		font-weight: 500
		@media screen and (min-width: 1000px)
			font-size: vw(13px)
			line-height: 1.2
	@media screen and (max-width: 1000px)
		.popup__title
			text-align: center
			font-size: 10vw
			line-height: 1.1
		.popup__subtitle
			text-align: center
</style>
