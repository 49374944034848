<template>
  <div class="popup__bg">
    <div class="popup w-1040">
      <div class="popup__container">
        <div class="popup__interactive"><FindGame @step="changeStep" /></div>
        <div class="popup__article" data-lenis-prevent v-if="step == 1">
          <div class="popup__article-content">
            <div
              class="popup__title"
              v-html="activeContent.title"
              :class="{
                'popup__title--small': activeContent.small_title,
                'popup__title--mini': activeContent.mini_title,
                'popup__title--small-mobile': activeContent.small_title_mobile,
                'popup__title--mini-mobile': activeContent.mini_title_mobile,
              }"
            ></div>
            <div class="popup__subtitle desktop-only" v-html="activeContent.info"></div>
            <div class="popup__text desktop-only" v-html="activeContent.text"></div>
            <div class="popup__text mobile-only">
              В честь юбилея Госуслуг, на нашем новогоднем изображении мы спрятали 11
              героев Госуслуг. Найди всех, чтобы узнать лайфхаки, которые помогут тебе в
              повседневной жизни!
            </div>
          </div>
        </div>
        <div class="popup__article" data-lenis-prevent v-if="step == 2">
          <div class="popup__article-content">
            <div class="popup__title popup__title--medium">Как играть</div>
            <div class="popup__text">
              <div class="popup__rules">
                <div class="popup__rules-item">
                  <div class="popup__rules-icon popup__rules-icon--1">
                    <img src="../assets/img/find-game/icon-1.svg" alt="" />
                  </div>
                  <div class="popup__rules-text">
                    Перемещайте иллюстрацию и ищите героев Госуслуг!
                  </div>
                </div>
                <div class="popup__rules-item">
                  <div class="popup__rules-icon popup__rules-icon--2">
                    <img src="../assets/img/find-game/icon-2.svg" alt="" />
                  </div>
                  <div class="popup__rules-text">
                    Ниже — подсказки с силуэтами героев. Нажимайте на персонажей, чтобы
                    открыть их информационные карточки.
                  </div>
                </div>
                <div class="popup__rules-item">
                  <div class="popup__rules-icon popup__rules-icon--3">
                    <img src="../assets/img/find-game/icon-3.svg" alt="" />
                  </div>
                  <div class="popup__rules-text">
                    Соберите всех, чтобы завершить игру и узнать больше о полезных
                    сервисах!
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="popup__article" data-lenis-prevent v-if="step == 3">
          <div class="popup__article-content">
            <div class="popup__title">Запись к врачу для детей</div>
            <div class="popup__text">
              <p>
                Пора на осмотр? Записывайтесь к врачу для ребенка через Госуслуги!
                Выберите подходящего специалиста и удобное время – никаких очередей и
                лишних звонков. Забота о здоровье еще никогда не была такой простой!
              </p>
            </div>
          </div>
        </div>
        <div class="popup__article" data-lenis-prevent v-if="step == 12">
          <div class="popup__article-content">
            <div class="popup__title">Оформление пенсии</div>
            <div class="popup__text">
              <p>
                Пора на заслуженный отдых? На Госуслугах можно подать заявление на
                оформление пенсии онлайн. Удобный сервис подскажет, какие документы нужны,
                и сообщит, когда все будет готово.
              </p>
            </div>
          </div>
        </div>
        <div class="popup__article" data-lenis-prevent v-if="step == 4">
          <div class="popup__article-content">
            <div class="popup__title popup__title--small">Оформление загранпаспорта</div>
            <div class="popup__text">
              <p>
                Собрались за границу? На Госуслугах можно подать заявление на
                загранпаспорт. Заполните анкету, оплатите пошлину со скидкой и следите за
                статусом – все в одном месте.
              </p>
            </div>
          </div>
        </div>
        <div class="popup__article" data-lenis-prevent v-if="step == 5">
          <div class="popup__article-content">
            <div class="popup__title">Получение дачи</div>
            <div class="popup__text">
              <p>
                Госуслуги помогут с оформлением дачи! От регистрации договора до
                постановки на учет – делайте все онлайн и забудьте про бумажную рутину.
              </p>
            </div>
          </div>
        </div>
        <div class="popup__article" data-lenis-prevent v-if="step == 6">
          <div class="popup__article-content">
            <div class="popup__title">Регистрация брака</div>
            <div class="popup__text">
              <p>
                Влюблены? На Госуслугах можно подать заявление на регистрацию брака всего
                за пару минут! Выберите дату, место и начните новую главу своей жизни без
                суеты.
              </p>
            </div>
          </div>
        </div>
        <div class="popup__article" data-lenis-prevent v-if="step == 7">
          <div class="popup__article-content">
            <div class="popup__title">Оформление социальных выплат</div>
            <div class="popup__text">
              <p>
                Большая семья – большая поддержка! На Госуслугах легко оформить социальные
                выплаты для многодетных. Подавайте заявление онлайн и получайте помощь, на
                которую вы имеете право.
              </p>
            </div>
          </div>
        </div>
        <div class="popup__article" data-lenis-prevent v-if="step == 8">
          <div class="popup__article-content">
            <div class="popup__title">Электронная трудовая книжка</div>
            <div class="popup__text">
              <p>
                Трудовая книжка теперь доступна в электронном формате! Оформите ее на
                Госуслугах, чтобы всегда иметь доступ к своему стажу и работодателям.
              </p>
            </div>
          </div>
        </div>
        <div class="popup__article" data-lenis-prevent v-if="step == 9">
          <div class="popup__article-content">
            <div class="popup__title">Электронный дневник</div>
            <div class="popup__text">
              <p>
                Будьте в курсе успехов ваших детей в школе! Электронный дневник через
                Госуслуги – это оценки, расписание и задания в одном месте, доступные с
                любого устройства.
              </p>
            </div>
          </div>
        </div>
        <div class="popup__article" data-lenis-prevent v-if="step == 10">
          <div class="popup__article-content">
            <div class="popup__title">Пособия на детей</div>
            <div class="popup__text">
              <p>
                На Госуслугах можно оформить пособия на детей – быстро и без лишней
                волокиты. Проверьте доступные выплаты и подайте заявление онлайн, чтобы
                уделить больше времени семье.
              </p>
            </div>
          </div>
        </div>
        <div class="popup__article" data-lenis-prevent v-if="step == 11">
          <div class="popup__article-content">
            <div class="popup__title">Получили штраф?</div>
            <div class="popup__text">
              <p>
                Не беда – на Госуслугах можно оплатить его со скидкой 50%! Главное условие
                – успеть сделать это в течение 20 дней с момента выписки. Удобный сервис
                сам найдет все задолженности, чтобы вы могли решить вопрос быстро,
                сэкономив время и деньги. <br />
                <br />
                С 1 января 2025 ожидается изменение условий оплаты.
              </p>
            </div>
          </div>
        </div>
        <div class="popup__article" data-lenis-prevent v-if="step == 13">
          <div class="popup__article-content">
            <div class="popup__title">Регистрация земельного участка</div>
            <div class="popup__text">
              <p>
                Купили участок? Зарегистрируйте его через Госуслуги! Заполните заявление
                онлайн, а о готовности документов вас уведомят автоматически.
              </p>
            </div>
          </div>
        </div>
        <div class="popup__article" data-lenis-prevent v-if="step == 14">
          <div class="popup__article-content">
            <div class="popup__title">Спасибо за игру!</div>
            <div class="popup__text">
              <p>
                Вы нашли всех героев! Теперь вы точно знаете, как Госуслуги делают жизнь
                проще. Пользуйтесь их возможностями – быстро, удобно и онлайн.
              </p>
            </div>
          </div>
        </div>
        <div class="popup__close" @click="$emit('close')"><span></span></div>
      </div>
    </div>
  </div>
</template>

<script>
import FindGame from "@/components/FindGame";
export default {
  name: "Popup_3",
  data() {
    return {
      step: 1,
    };
  },
  props: {
    activeContent: {
      type: Object,
      required: true,
    },
  },
  components: {
    FindGame,
  },
  methods: {
    changeStep(step) {
      this.step = step;
    },
  },
};
</script>

<style lang="sass" scoped>
@import '@/assets/sass/functions.sass'
@import '@/assets/sass/popup.sass'
.popup.w-1040
  width: vw(1064px)
  @media screen and (max-width: 1000px)
    width: 100vw
  .popup__interactive
    width: vw(620px)
  .popup__article
    width: vw(424px)
    margin-right: vw(-20px)
    margin-left: vw(20px)
    @media screen and (max-width: 1000px)
      margin-right: 0
      width: 100%
      margin-left: 0
      margin-bottom: vwm(10px)
  .popup__close
    position: relative
    right: vw(-40px)
    @media screen and (max-width: 1000px)
      right: 0

  .popup
    &__container
      @media screen and (max-width: 1000px)
        .popup__close
          order: 1
        .popup__article
          order: 2
        .popup__interactive
          order: 3
    &__title
      @media screen and (max-width: 1000px)
        font-size: vwm(35px)
        line-height: vwm(35px)
    &__rules
      padding-top: vw(5px)
      @media screen and (max-width: 1000px)
        padding-top: vwm(10px)
      &-item
        display: flex
        align-items: flex-start
      &-icon
        margin-right: vw(12px)
        @media screen and (max-width: 1000px)
          margin-right: vwm(15px)
        img
          width: 100%
          padding-top: vw(5px)
        &--1
          width: vw(25px)
          height: vw(25px)
          @media screen and (max-width: 1000px)
            width: vwm(25px)
        &--2
          width: vw(55px)
          @media screen and (max-width: 1000px)
            width: vwm(65px)
        &--3
          width: vw(40px)
          @media screen and (max-width: 1000px)
            width: vwm(40px)
      &-text
        font-size: vw(15px)
        line-height: vw(22px)
        font-weight: 500
        padding-bottom: vw(8px)
        @media screen and (max-width: 1000px)
          font-size: vwm(15px)
          line-height: vwm(22px)
          font-weight: 500
          padding-bottom: vwm(10px)
.desktop-only
  @media screen and (max-width: 1000px)
    display: none
.mobile-only
  @media screen and (min-width: 1001px)
    display: none
</style>
