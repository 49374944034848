<template>
  <div class="main" :class="{ active: !welcome }" @click="justClick">
    <transition name="fade">
      <Scroll @open="openModal" :autoScroll="autoScroll" v-show="!fade" />
    </transition>
    <transition name="fade">
      <Welcome
        :active="false"
        v-if="welcome"
        @start="start"
        @toggle="toggleSound"
        :sound="sound"
      />
    </transition>
    <transition name="fade">
      <Interface
        v-if="!welcome && !fade"
        :modal="modal"
        @open="openBurger"
        @close="closeBurger"
        :activeContent="activeContent"
        :sound="sound"
        :welcome="welcome"
        :autoScroll="autoScroll"
        @togglecsroll="toggleAutoScroll"
        @stopautoscroll="stopautoscroll"
        @openWelcome="welcome = true"
      />
    </transition>
    <transition name="fade2">
      <Modal
        v-if="modal && active && activeContent && !fade"
        @close="closeModal"
        :activeContent="activeContent"
      />
    </transition>
    <transition name="fade">
      <div class="white-screen" v-if="fade"></div>
    </transition>
  </div>
</template>

<script>
import Lenis from "@studio-freight/lenis";
import scrollLock from "scroll-lock";
import { addScrollableSelector } from "scroll-lock";
import Welcome from "@/components/Welcome";
import Scroll from "@/components/Scroll";
import Interface from "@/components/Interface";
import Modal from "@/components/Modals.vue";
export default {
  name: "Main",
  components: {
    Welcome,
    Scroll,
    Interface,
    Modal,
  },
  computed: {
    activeContent() {
      if (this.active) {
        return this.screen[this.active];
      }
      return null;
    },
  },
  data() {
    return {
      l: null,
      welcome: true,
      modal: false,
      active: true,
      sound: false,
      autoScroll: false,
      scrollInterval: null,
      fade: false,
      screen: {
        "1-1": {
          type: 3,
          title: "VI век до н.э. ПЕРВЫЕ ПОЧТОВЫЕ СИСТЕМЫ",
          info:
            "Всё началось с сигнальных костров и барабанного боя. Так первобытные люди извещали друг друга о приближении чужаков или победе.",
          text:
            "<p>В 6 веке до н.э. появилась первая почта. Её повелел создать персидский царь Кир Великий — он негодовал, что его приказы шли долго и часто терялись. Ещё через сто лет в Персии и Сирии родилась голубиная почта — люди заметили, что голубь всегда летит домой, и стали брать птиц с собой в путешествия. Человечеству предстояло одолеть путь в несколько веков, чтобы изобрести по-настоящему эффективную систему связи. Главные открытия пришлись на 19-е и 20-е столетия. Неоценимый вклад в развитие связи внесли отечественные изобретатели.</p>",
          quiz: {
            title: "Древние способы связи",
            text:
              "Без телефонов и мессенджеров нашим предкам приходилось ой как несладко. На что только в древности люди ни шли, только чтобы спросить «Как дела?» или сообщить «Заболел, охотиться на мамонта не выйду». А действительно — на что? Проверьте знания — пройдите короткий тест!",
            items: [
              {
                type: 2,
                question:
                  "Сообщения можно передавать с помощью огня и дыма. Для этого вдоль Великой китайской стены построили сторожевые башни. Примерно сколько их?",
                answers: [
                  {
                    text: "500",
                    correct: false,
                  },
                  {
                    text: "1 000",
                    correct: false,
                  },
                  {
                    text: "10 000",
                    correct: true,
                  },
                ],
                correct_text:
                  "На Великой китайской стене около 10 тыс. сигнальных башен. И в каждой располагался гарнизон из 50 солдат. Представляете, какое фаер-шоу они могли устроить?",
                wrong_text:
                  "На Великой китайской стене около 10 тыс. сигнальных башен. И в каждой располагался гарнизон из 50 солдат. Представляете, какое фаер-шоу они могли устроить?",
              },
              {
                type: 1,
                question:
                  "В Западной Африке до сих пор используют говорящие барабаны. Как именно они передают сообщения?",
                answers: [
                  {
                    text: "Используют код по принципу азбуки Морзе",
                    correct: false,
                  },
                  {
                    text: "Имитируют человеческую речь",
                    correct: true,
                  },
                  {
                    text: "Извещают об опасности с помощью ритма",
                    correct: false,
                  },
                ],
                correct_text:
                  "Высота звука важна не только в музыке, но и в некоторых языках — тональных. Так что барабаны просто копируют речь человека.",
                wrong_text:
                  "Высота звука важна не только в музыке, но и в некоторых языках — тональных. Так что барабаны просто копируют речь человека.",
              },
              {
                type: 2,
                question:
                  "Тирольское пение веками помогало пастухам общаться между собой и управлять стадами. Как оно называется?",
                answers: [
                  {
                    text: "Йодль",
                    correct: true,
                  },
                  {
                    text: "Йоль",
                    correct: false,
                  },
                  {
                    text: "Йод",
                    correct: false,
                  },
                ],
                correct_text:
                  "Задорная трель, которая разносится по горам, — это йодль. В России тоже есть особенный голосовой сигнал — «ау!». Каждый из нас хорошо знает его с детства.",
                wrong_text:
                  "Задорная трель, которая разносится по горам, — это йодль. В России тоже есть особенный голосовой сигнал — «ау!». Каждый из нас хорошо знает его с детства.",
              },
              {
                type: 1,
                question:
                  "Во многих странах существовала голубиная связь. Как называлось письмо, отправленное голубиной почтой?",
                answers: [
                  {
                    text: "Голубиная депеша",
                    correct: false,
                  },
                  {
                    text: "Воздушный поцелуй",
                    correct: false,
                  },
                  {
                    text: "Голубеграмма",
                    correct: true,
                  },
                ],
                correct_text:
                  "Да-да, голуби-почтальоны доставляли именно голубеграммы. Для их доставки даже печатали специальные марки. Вот только кодов отслеживания у них не было.",
                wrong_text:
                  "Да-да, голуби-почтальоны доставляли именно голубеграммы. Для их доставки даже печатали специальные марки. Вот только кодов отслеживания у них не было.",
              },
              {
                type: 1,
                question:
                  "Угадайте, в каком году была закрыта последняя официальная голубиная служба.",
                answers: [
                  {
                    text: "В 1957 году",
                    correct: false,
                  },
                  {
                    text: "В 2008 году",
                    correct: true,
                  },
                  {
                    text: "В 2019 году",
                    correct: false,
                  },
                ],
                correct_text:
                  "Вы не поверите, но в Индии голубиная почтовая служба работала аж до 2008 года.",
                wrong_text:
                  "Вы не поверите, но в Индии голубиная почтовая служба работала аж до 2008 года.",
              },
            ],
            success_text:
              "Вашим знаниям можно позавидовать, вы — ходячая энциклопедия. Эрудиция — одно из важных качеств нашего времени, продолжайте в том же духе.",
            fail_text:
              "Чего-то не знать — не страшно, страшнее ничем не интересоваться. В любом случае вы  узнали много новой информации и теперь можете проверить, что из этого вы смогли запомнить.",
          },
          small_title: true,
        },
        "1-2": {
          type: 14,
          title: "Связь поколений",
          info:
            "Представляем династии сотрудников «Ростелекома» – семьи, которые на протяжении поколений посвящают свою жизнь профессии связистов.",
          text:
            "<p>Они гордятся тем, что продолжают дело своих родителей, ведь эта работа — не просто работа, а призвание. </p><p>Сила связи объединяет их, а уникальные истории и достижения каждой семьи вдохновляют на новые свершения. </p><p>Узнайте больше о вкладе этих династий в развитие связи и телекома!</p>",
          game: 1,
          viewer: 1,
          small_title: false,
          small_title_mobile: true,
        },
        "1-3": {
          type: 15,
          title: "Сила технологий",
          info:
            "Преобразите свое фото в оригинальный аватар для мессенджеров и соцсетей.",
          text:
            "<p>Используйте силу искусственного интеллекта, чтобы удивить друзей и коллег, сделав уникальный аватар в трех разных стилистиках!</p>",
          game: 1,
          viewer: 1,
          small_title: false,
          small_title_mobile: true,
        },
        "1-4": {
          type: 16,
          title: "Истории <span></span>связи",
          text:
            "<p>В этом разделе вы найдете вдохновляющие истории о повседневной работе и личных достижениях. Присоединяйтесь и делитесь своими историями!</p><div class='histories__manual'>Чтобы перемещаться по историям, зажмите левую кнопку мыши и перетащите <br/>их в нужную сторону</div>",
          game: 1,
          viewer: 1,
          small_title: false,
          small_title_mobile: true,
        },
        "4-1": {
          type: 2,
          title: "1832. ПЕРВЫЙ В&#160;МИРЕ ЭЛЕКТРОМАГНИТНЫЙ ТЕЛЕГРАФ",
          info:
            "21 октября 1832 г. российский дипломат, востоковед, барон Павел Шиллинг провёл в Санкт-Петербурге демонстрацию «дальноизвещающей машины».",
          text:
            "<p>Посмотреть на неё приходил даже император Николай I. В 1835 г. Шиллинг продемонстрировал своё изобретение на Западе. Один аппарат приобрёл студент Уильям Кук, привёз в Англию и показал физику-изобретателю Уитстону. Тот изменил некоторые детали, запатентовал «Электромагнитный телеграф системы Уитстон-Кук» и начал серийный выпуск аппаратов. Сам Шиллинг внедрить своё детище в России не успел — скончался, не успев выполнить повеления императора соединить телеграфом Петербург с Кронштадтом. Его дело продолжил Борис Семёнович Якоби.</p>",
          game: 1,
          viewer: 1,
          small_title: true,
          small_title_mobile: true,
        },
        "8-1": {
          type: 1,
          title: "1876. ПЕРВЫЙ <br/>В МИРЕ ТЕЛЕФОН",
          info:
            "7 марта 1876 г. Александр Белл (США) получил патент на трубку, служившую по очереди для передачи и приёма речи. Абонент вызывался при помощи свистка, дальность действия не превышала 500 м. В 1878 г. электротехник Павел Голубицкий разработал первый русский телефон оригинальной конструкции.",
          text:
            "<p>Его с успехом приняли во всём мире. Уже в 1880 г. на Царскосельской железной дороге заработала первая междугородная сеть, а 25 сентября 1881 г. Александр III утвердил положение «Об устройстве городских телефонных сообщений»: телефонные линии начали победоносное шествие по стране. Событие стало отправной точкой развития связи. Именно поэтому 25 сентября отмечается День компании «Ростелеком».</p>",
          viewer: 2,
        },
        "13-1": {
          type: 4,
          title: "1880. ПЕРВЫЙ <br/>В МИРЕ ТАКСОФОН",
          info:
            "Первую публичную телефонную будку открыли в США (штат Коннектикут) 1 июня 1880 г. Таксофон не был автоматическим — платить за разговор нужно было приставленному сотруднику.",
          text:
            "<p>Механизм приёма монет приладили только через девять лет. В 1889 г. такой таксофон появился в отделении банка Hartford: монеты нужно было опускать после разговора. В Москве первые «переговорные павильоны» решили установить в 1903 г. Через шесть лет в городе насчитывалось 26 телефонов-автоматов. Особенно популярными автоматы стали в советские годы. Сначала они принимали оплату монетами, потом жетонами, а к концу 20-го века автоматы перевели на специальные карточки (их надо было покупать заранее).</p>",
          game: 2,
          viewer: 3,
        },
        "16-1": {
          type: 5,
          title: "1895. <br/>ПЕРВЫЙ В МИРЕ РАДИОПРИЁМНИК",
          info:
            "7 мая 1895 г. русский физик и электротехник Александр Попов показал возможность передачи и приёма коротких и продолжительных сигналов на расстояние до 64 м. Своим изобретением он внёс огромный вклад в развитие радиосвязи.",
          text:
            "<p>Поэтому 7 мая в нашей стране отмечается День радио. А 17 сентября 1922 г. в эфире советского радио прошёл первый в истории радиоконцерт. Открывала его Надежда Обухова романсом «Не искушай меня без нужды». Так в СССР началось регулярное радиовещание. Наши специалисты связи стали первыми в Европе, кому удалось создать комплекс мощных радиовещательных станций. Долгие годы советское радиостроение оставалось мировым лидером.</p>",
          viewer: 4,
          track: 2,
          small_title_mobile: true,
          is_disable_music: true,
        },
        "19-1": {
          type: 9,
          title: "1929. <br/>ПЕРВАЯ В&#160;СССР <br/>МАШИННАЯ АТС",
          info:
            "Первая советская автоматическая телефонная станция машинного типа ёмкостью 6 тыс. номеров была пущена в работу в Ростове-на-Дону 3 августа 1929 г.",
          text:
            "<p>К этому времени в городе набралось уже свыше 3,5 тыс. абонентов — телефонистки с трудом выполняли свою работу, поэтому и было принято решение строить автоматическую станцию. Оборудование изготавливали в Ленинграде. К концу 20-х в стране функционировало лишь два опытных образца АТС разных типов (оба в столице). Уже в октябре 1930 г. в Москве запустили ещё две новые АТС машинной системы на 7 и 8 тысяч номеров. Такие АТС было решено широко внедрять в крупных городах CCCР. Технологии связи в нашей стране стремительно развивались.</p>",
          game: 11,
          small_title: true,
        },
        "19-2": {
          type: 5,
          title: "1942. МУЗЫКАЛЬНЫЕ ТРАНСЛЯЦИИ <br/>В БЛОКАДНОМ ЛЕНИНГРАДЕ ",
          info:
            "Великая Отечественная война стала одновременно испытанием и периодом бурного роста для отрасли связи, а наши связисты совершили немало боевых и трудовых подвигов, внеся неоценимый вклад в Победу. Порою именно связь помогала людям окончательно не пасть духом.",
          text:
            "<p>Так, по донесениям советской разведки, на 9 августа 1942 г. Гитлер назначил прорыв обороны Ленинграда, уже 11 месяцев находящегося в блокаде. Прорыв не состоялся. Зато в этот день состоялся знаменитый радиоконцерт — Большой симфонический Оркестр Ленинградского радиокомитета исполнял Седьмую симфонию Дмитрия Шостаковича. Более чем за два года войны оркестр дал 160 концертов, и почти все из них транслировались по городской радиотрансляционной сети, морально поддерживая ленинградцев. Предлагаем послушать один из них.</p>",
          viewer: 5,
          track: 1,
          small_title_mobile: true,
          is_disable_music: true,
        },
        "22-1": {
          type: 9,
          title: "1956. ПЕРВЫЙ <br/>В МИРЕ ПЕЙДЖЕР",
          info:
            "1 апреля 1956 г. компания Motorola представила пейджер, который быстро стал полезен внутри больниц и офисов. Число абонентов сети не могло быть больше 57, а радиус действия не превышал 200 метров.",
          text:
            "<p>В СССР пейджеры появились в конце 1970-х и использовались в основном скорой помощью и другими службами в период проведения Олимпиады-80. Особую популярность пейджеры в нашей стране получили в 1990-е. Сначала они были модным аксессуаром обеспеченных людей, а во второй половине 90-х стали распространяться практически повсеместно. В первой половине 2000-х в связи с развитием мобильной связи пейджеры постепенно вышли из обихода.</p>",
          game: 12,
        },
        "22-2": {
          type: 4,
          title: "1957. ПЕРВЫЙ ИСКУССТВЕННЫЙ СПУТНИК ЗЕМЛИ",
          info:
            "4 октября 1957 г. на околоземную орбиту был выведен первый в мире искусственный спутник Земли — и родиной его был Советский Союз!",
          text:
            "<p>Спутник доставила на орбиту ракета-носитель Р-7, запущенная с будущего космодрома «Байконур». Он провёл на орбите 92 дня, совершив 1440 оборотов вокруг Земли (около 60 млн км). Запуск спутника имел громадное значение для познания свойств космического пространства, изучения Земли как планеты и развития не только отечественной, но и мировой отрасли связи. В сентябре 1967 г. Международная федерация астронавтики провозгласила 4 октября Днём начала космической эры человечества. И эту эру открыла наша страна!</p>",
          game: 3,
          viewer: 6,
          small_title: true,
          small_title_mobile: true,
        },
        "25-1": {
          type: 10,
          title: "1963. ПЕРВАЯ АВТОМАТИЧЕСКАЯ МОБИЛЬНАЯ СВЯЗЬ В&#160;СССР",
          info:
            "6 августа 1963 г. в СССР была внедрена в эксплуатацию система мобильной связи «Алтай». Главным образом её устанавливали в автомобили руководителей высокого уровня.",
          text:
            "<p>Чтобы позвонить на другой «Алтай» или на обычный телефон, достаточно было набрать номер — как на обычном телефонном аппарате, без всяких переключений каналов или разговоров с диспетчером. К 1983 г. система обслуживала уже около 135 городов СССР и 30 тысяч абонентов — в первую очередь по делам государственной важности. В результате распада СССР российский рынок заняли импортные сотовые телефоны стандарта 2G и 3G, а отечественная разработка «Алтай» сначала ушла в тень, а впоследствии была и вовсе забыта.</p>",
          viewer: 8,
          small_title: true,
          small_title_mobile: true,
        },
        "28-1": {
          type: 6,
          title: "1969. ПОЯВЛЕНИЕ ИНТЕРНЕТА",
          info:
            "2 сентября 1969 г. группа американских учёных под руководством Леонарда Клейнрока установила канал передачи данных с компьютера на компьютер через кабель внутри сети ARPANET. Этот день считается началом эры интернета.",
          text:
            "<p>В СССР первый проект сети, сравнимой с интернетом, руководству страны представили ещё раньше, в 1959 г. Позднее в Ленинградском вычислительном центре учёные работали над созданием «коллективной человеко-машины» и цифровых линий связи, а «всесоюзная академсеть» связала научные учреждения Академии наук СССР. Отечественные коммуникационные системы существенно превосходили достижения американцев, но, к сожалению, не получали должного внимания и финансирования.</p>",
        },
        "30-1": {
          type: 7,
          title: "1991. ПЕРВЫЙ РАЗГОВОР <br/>ПО СОТОВОМУ ТЕЛЕФОНУ <br/>В РОССИИ",
          info:
            "Мэр Ленинграда Анатолий Собчак и мэр Сиэтла (США) Норман Райс провели 9 сентября 1991 г. первый разговор по сотовому телефону Nokia. Демонстрационный звонок был совершён через сеть оператора «Дельта Телеком» (позднее «Скай Линк» – дочерняя компания «Ростелекома»).",
          text:
            "<p>В 1991 г. подключение к «Дельте» обходилось примерно в 5 тыс. долларов, а минута разговора стоила около 1 доллара. В последующие годы мобильная связь в нашей стране бурно развивалась, становясь всё доступнее. Сегодня в России взят курс на создание и производство оборудования и решений для мобильной связи уже 5-го поколения. Выполнение этой важной задачи взял на себя «Ростелеком».</p>",
          quiz: {
            title: "Эпоха мобильной связи",
            text:
              "А сейчас на нашей машине времени перенесемся в 9 сентября 1991 года, когда в России состоялся первый разговор по сотовому телефону. Проверим, можно ли вас назвать мобильным гуру. ",
            items: [
              {
                type: 1,
                question: "Кто совершил первый звонок по сотовому телефону?",
                answers: [
                  {
                    text: "Анатолий Собчак",
                    correct: true,
                  },
                  {
                    text: "Борис Ельцин",
                    correct: false,
                  },
                  {
                    text: "Михаил Горбачев",
                    correct: false,
                  },
                ],
                correct_text:
                  "Исторический звонок совершил мэр Петербурга Анатолий Собчак, чтобы пообщаться со своим американским коллегой — мэром Сиэтла Норманом Райсом.",
                wrong_text:
                  "Исторический звонок совершил мэр Петербурга Анатолий Собчак, чтобы пообщаться со своим американским коллегой — мэром Сиэтла Норманом Райсом.",
              },
              {
                type: 2,
                question: "Звонок был сделан по аппарату Nokia. Сколько он весил?",
                answers: [
                  {
                    text: "0,3 кг",
                    correct: false,
                  },
                  {
                    text: "1 кг",
                    correct: false,
                  },
                  {
                    text: "3 кг",
                    correct: true,
                  },
                ],
                correct_text:
                  "Держите факт: мобильник весил почти 3 килограмма. Не зря телефоны того времени называли «кирпичами».",
                wrong_text:
                  "Держите факт: мобильник весил почти 3 килограмма. Не зря телефоны того времени называли «кирпичами».",
              },
              {
                type: 2,
                question: "Сколько в 1991 году стоила одна минута разговора?",
                answers: [
                  {
                    text: "10 центов",
                    correct: false,
                  },
                  {
                    text: "1 доллар",
                    correct: true,
                  },
                  {
                    text: "2,5 доллара",
                    correct: false,
                  },
                ],
                correct_text:
                  "Исходящие и входящие звонки стоили около 1 доллара. Если перевести на современные деньги, то с учетом инфляции получится около 2000 рублей. На телефонных разговорах <br/>с мамой можно было разориться!",
                wrong_text:
                  "Исходящие и входящие звонки стоили около 1 доллара. Если перевести на современные деньги, то с учетом инфляции получится около 2000 рублей. На телефонных разговорах  <br/>с мамой можно было разориться!",
              },
            ],
            success_text:
              "Вашим знаниям можно позавидовать, вы — ходячая энциклопедия. Эрудиция — одно из важных качеств нашего времени, продолжайте в том же духе.",
            fail_text:
              "Чего-то не знать — не страшно, страшнее ничем не интересоваться. В любом случае вы  узнали много новой информации и теперь можете проверить, что из этого вы смогли запомнить.",
          },
          viewer: 9,
          small_title: true,
        },
        "32-1": {
          type: 3,
          title: "1992. СОЗДАНИЕ КОМПАНИИ «РОСТЕЛЕКОМ»",
          info:
            "30 декабря 1992 г. создана государственная компания «Ростелеком» (преемница «Совтелекома») — единственный оператор дальней связи, оказывающий услуги на всей территории страны. 25 сентября 1993 г. «Ростелеком» был акционирован.",
          text:
            "<p>Становление компании неразрывно связано с активным развитием Рунета. 7 апреля 1994 г. в нашей стране был официально зарегистрирован первый домен в зоне RU — каталог страниц на английском и русском языках Russia on the Net. В 1997 г. «Ростелеком» получил статус интернет-сервис-провайдера и начал предоставлять доступ в глобальную сеть не только операторам различных сетей связи, но и отдельным пользователям. В 2008 г. «Ростелеком» стал национальным оператором реестра доменов .ru и .рф.</p>",
          quiz: {
            title: "Зарегистрировано первое доменное имя в зоне .RU",
            title_small: true,
            text:
              "Если вы много путешествуете и хотя бы раз пытались скачать фильм или провести видеоконференцию по пути из одной страны в другую, то знаете, что российский интернет — один из самых быстрых и надежных в мире. Наверное, потому, что развивался он уж очень стремительно. Примите участие в викторине! Уверены, многие факты о Рунете вас приятно удивят. ",
            items: [
              {
                type: 1,
                question:
                  "Между какими российскими городами впервые в истории состоялся сеанс интернет-связи?",
                answers: [
                  {
                    text: "Москва — Казань",
                    correct: true,
                  },
                  {
                    text: "Санкт-Петербург — Владивосток",
                    correct: false,
                  },
                  {
                    text: "Москва — Барнаул",
                    correct: false,
                  },
                ],
                correct_text:
                  "Только представьте, в феврале 1991 года состоялся первый сеанс модемной связи между Москвой и Казанью. А уже 1 мая количество внутрироссийского сетевого трафика (зарегистрированных пользователей и проведенных сеансов связи) превысило обмен между Россией и Западом. <br/>Три месяца — и Рунет готов. <br/>Получите — распишитесь!",
                wrong_text:
                  "Только представьте, в феврале 1991 года состоялся первый сеанс модемной связи между Москвой и Казанью. А уже 1 мая количество внутрироссийского сетевого трафика (зарегистрированных пользователей и проведенных сеансов связи) превысило обмен между Россией и Западом. <br/>Три месяца — и Рунет готов. <br/>Получите — распишитесь!",
              },
              {
                type: 2,
                question:
                  "Какая поисковая система была основной в Рунете <br>в 1990–2000-х? ",
                answers: [
                  {
                    text: "«Рамблер»",
                    correct: true,
                  },
                  {
                    text: "«Яндекс»",
                    correct: false,
                  },
                  {
                    text: "Mail.ru",
                    correct: false,
                  },
                ],
                correct_text:
                  "Зумеры, возможно, не помнят, но на заре Рунета самой посещаемой поисковой системой был именно Rambler. Он был создан в 1996 году и закреплен за адресом www.rambler.ru.",
                wrong_text:
                  "Зумеры, возможно, не помнят, но на заре Рунета самой посещаемой поисковой системой был именно Rambler. Он был создан в 1996 году и закреплен за адресом www.rambler.ru.",
              },
              {
                type: 1,
                question:
                  " Какое первое офлайн-СМИ зарегистрировало свой сайт в Рунете? ",
                answers: [
                  {
                    text: "«Учительская газета»",
                    correct: true,
                  },
                  {
                    text: "«Анекдот.ру»",
                    correct: false,
                  },
                  {
                    text: "«РосБизнесКонсалтинг»",
                    correct: false,
                  },
                ],
                correct_text:
                  "«Учительская газета»? Что? Да! В 1995 году именно она закрепила за собой право первой выпустить электронную версию своего издания. А уже после нее был зарегистрирован сайт «РосБизнесКонсалтинг». И только в конце года — «Анекдот.ру».",
                wrong_text:
                  "«Учительская газета»? Что? Да! В 1995 году именно она закрепила за собой право первой выпустить электронную версию своего издания. А уже после нее был зарегистрирован сайт «РосБизнесКонсалтинг». И только в конце года — «Анекдот.ру».",
              },
              {
                type: 1,
                question:
                  " В 1996 году регистрация в доменной зоне .ru стала платной. Сколько стоила регистрация?",
                answers: [
                  {
                    text: "100 долларов",
                    correct: true,
                  },
                  {
                    text: "25 долларов",
                    correct: false,
                  },
                  {
                    text: "50 долларов",
                    correct: false,
                  },
                ],
                correct_text:
                  "Зарегистрироваться в доменной зоне .ru можно было за 100 долларов. Такое решения приняла координационная группа домена .ru в 1996 году. Но, кстати, вскоре цена снизилась до 80 долларов.",
                wrong_text:
                  "Зарегистрироваться в доменной зоне .ru можно было за 100 долларов. Такое решения приняла координационная группа домена .ru в 1996 году. Но, кстати, вскоре цена снизилась до 80 долларов.",
              },
              {
                type: 1,
                question:
                  "Какое политическое событие впервые произошло в сети в 1995 году? ",
                answers: [
                  {
                    text: "Опубликованы промежуточные результаты выборов ",
                    correct: true,
                  },
                  {
                    text:
                      "Проведена первая интернет-конференция действующего президента России Бориса Ельцина",
                    correct: false,
                  },
                  {
                    text:
                      "Трансляция встречи премьер-министра России с представителями интернет-общественности",
                    correct: false,
                  },
                ],
                correct_text:
                  "Сегодня голосовать онлайн — удобная привычка. Но впервые это произошло в декабре 1995 года, когда опубликовали результаты выборов депутатов Государственной думы.",
                wrong_text:
                  "Сегодня голосовать онлайн — удобная привычка. Но впервые это произошло в декабре 1995 года, когда опубликовали результаты выборов депутатов Государственной думы.",
              },
            ],
            success_text:
              "Вашим знаниям можно позавидовать, вы — ходячая энциклопедия. Эрудиция — одно из важных качеств нашего времени, продолжайте в том же духе.",
            fail_text:
              "Чего-то не знать — не страшно, страшнее ничем не интересоваться. В любом случае вы  узнали много новой информации и теперь можете проверить, что из этого вы смогли запомнить.",
          },
          small_title: true,
          small_title_mobile: true,
        },
        "35-1": {
          type: 3,
          title: "2009. ГОСУСЛУГИ",
          info:
            "Запущена первая версия портала госуслуг. В 2012 г. вышло мобильное приложение, в 2016 г. появилась новая версия портала, а в 2021 г. проведено полное обновление Госуслуг.",
          text:
            "<p>Так появился цифровой помощник робот Макс, отвечающий на вопросы, помогающий найти услугу и пройти пользовательский путь. Сегодня Госуслуги — единый портал государственных и муниципальных услуг для граждан и бизнеса. Ежедневно им пользуются более 10 млн человек. В рамках проекта внедрены мобильные приложения «Госключ», «Госуслуги Авто», «Госуслуги Культура», «Госуслуги Решаем вместе» и др. За поддержку и развитие портала отвечает «РТ Лабс» — дочерняя компания «Ростелекома».</p>",
          quiz: {
            title: "Все сервисы под рукой",
            text:
              "Привет! Это робот-помощник Макс. Вы уже знакомы?  Он поможет вам лучше узнать историю и функционал портала государственных услуг. Для этого предлагаем ответить  на несколько вопросов.",
            showMax: true,
            items: [
              {
                type: 2,
                question:
                  "Идентификация по биометрии сегодня не выглядит как фильм о будущем. Это привычная реальность. Знаете ли вы, с какого года пользователи могут получать доступ  к порталу по биометрическим данным?",
                answers: [
                  {
                    text: "2022",
                    correct: true,
                  },
                  {
                    text: "2021",
                    correct: false,
                  },
                  {
                    text: "2020",
                    correct: false,
                  },
                ],
                correct_text:
                  "Войти в Госуслуги по биометрии стало возможно с 2022 года. Биометрические данные можно загрузить самостоятельно. Использовать их или нет — зависит от вашего желания. Кстати, и портал Госуслуг, и Единая биометрическая система — разработки «Ростелекома».",
                wrong_text:
                  "Войти в Госуслуги по биометрии стало возможно с 2022 года. Биометрические данные можно загрузить самостоятельно. Использовать их или нет — зависит от вашего желания. Кстати, и портал Госуслуг, и Единая биометрическая система — разработки «Ростелекома».",
              },
              {
                type: 1,
                question: "Какую услугу невозможно получить  на Госуслугах?",
                answers: [
                  {
                    text: "Получить заграничный паспорт",
                    correct: true,
                  },
                  {
                    text: "Записать ребенка в детский сад",
                    correct: false,
                  },
                  {
                    text: "Получить шенгенскую визу",
                    correct: true,
                  },
                ],
                correct_text:
                  " На Госуслугах можно сделать почти все. Например, записать ребенка в детский сад и подать запрос  на выпуск заграничного паспорта. А вот за шенгенской  визой — это вам в консульство.",
                wrong_text:
                  " На Госуслугах можно сделать почти все. Например, записать ребенка в детский сад и подать запрос  на выпуск заграничного паспорта. А вот за шенгенской  визой — это вам в консульство.",
              },
              {
                type: 1,
                question:
                  " На портале можно оплачивать госпошлины и штрафы. На какой из этих пунктов не распространяется скидка 30%?",
                answers: [
                  {
                    text: "Регистрация брака",
                    correct: false,
                  },
                  {
                    text: "Регистрация ИП",
                    correct: false,
                  },
                  {
                    text: "Оплата штрафов за нарушение ПДД",
                    correct: true,
                  },
                ],
                correct_text:
                  "У нас для вас две новости. Плохая: скидка в 30%  не распространяется на оплату штрафов за нарушение ПДД. Хорошая: оплата штрафов через портал доступна  с 50%-ной скидкой, если сделать это в первые 20 дней  с даты постановления.",
                wrong_text:
                  "У нас для вас две новости. Плохая: скидка в 30%  не распространяется на оплату штрафов за нарушение ПДД. Хорошая: оплата штрафов через портал доступна  с 50%-ной скидкой, если сделать это в первые 20 дней  с даты постановления.",
              },
              {
                type: 1,
                question: " Какой самый быстрый способ регистрации  на портале?",
                answers: [
                  {
                    text: "Онлайн-регистрация через банк",
                    correct: true,
                  },
                  {
                    text: "С помощью заказного письма",
                    correct: false,
                  },
                  {
                    text: "Через Центр госуслуг",
                    correct: false,
                  },
                ],
                correct_text:
                  "За 10 минут можно послушать две песни, проехать две станции метро, приготовить кофе и зарегистрироваться на Госуслугах через онлайн-банк. Весь процесс действительно занимает не более 10 минут, а по итогу пользователь портала сразу получает подтвержденную учетную запись. Самый долгий способ регистрации — с помощью заказного письма. На обработку запроса может уйти до 15 дней.",
                wrong_text:
                  "За 10 минут можно послушать две песни, проехать две станции метро, приготовить кофе и зарегистрироваться на Госуслугах через онлайн-банк. Весь процесс действительно занимает не более 10 минут, а по итогу пользователь портала сразу получает подтвержденную учетную запись. Самый долгий способ регистрации — с помощью заказного письма. На обработку запроса может уйти до 15 дней.",
              },
              {
                type: 1,
                question: "Кем защищены персональные данные пользователей на Госуслугах?",
                answers: [
                  {
                    text: "ФСБ",
                    correct: false,
                  },
                  {
                    text: "Министерство связи",
                    correct: false,
                  },
                  {
                    text: "Министерство цифрового развития",
                    correct: true,
                  },
                ],
                correct_text:
                  "Вдох-выдох — и полное спокойствие! Ваши данные надежно защищены Министерством цифрового развития Российской Федерации.  Для защиты данных используются технологии, аттестованные по требованиям ФСБ и ФСТЭК. Программное обеспечение Госуслуг ежегодно проходит сертификацию и аттестацию.",
                wrong_text:
                  "Вдох-выдох — и полное спокойствие! Ваши данные надежно защищены Министерством цифрового развития Российской Федерации.  Для защиты данных используются технологии, аттестованные по требованиям ФСБ и ФСТЭК. Программное обеспечение Госуслуг ежегодно проходит сертификацию и аттестацию.",
              },
            ],
            success_text:
              "Вашим знаниям можно позавидовать, вы — ходячая энциклопедия. Эрудиция — одно из важных качеств нашего времени, продолжайте в том же духе.",
            fail_text:
              "Чего-то не знать — не страшно, страшнее ничем не интересоваться. В любом случае вы  узнали много новой информации и теперь можете проверить, что из этого вы смогли запомнить.",
          },
        },
        "35-2": {
          type: 9,
          title: "2012. НАЦИОНАЛЬНАЯ ОБЛАЧНАЯ ПЛАТФОРМА",
          info:
            "«Ростелеком» представил Национальную облачную платформу — комплекс технических и программных средств на базе распределённой сети центров обработки данных (ЦОД) для оказания услуг по модели облачных вычислений.",
          text:
            "<p>Сегодня на ресурсах платформы работают облачные услуги «Виртуальный ЦОД», «Виртуальное хранилище», прикладные сервисы в сфере здравоохранения, образования, безопасности, ЖКХ, имущественно-земельных отношений, сервисы «Видеонаблюдение», «Виртуальная АТС» и многие другие. В 2022 г. «Ростелеком» успешно реализовал проект «ГосОблако» — перевёл на единую облачную платформу важнейшие информационные системы и ресурсы государства.</p>",
          game: 4,
          small_title: true,
          mini_title_mobile: true,
        },
        "37-1": {
          type: 3,
          title: "2012. ЦИФРОВЫЕ ВЫБОРЫ",
          info:
            "С 2012 г. «Ростелеком» организует видеонаблюдение за голосованием и подсчётом голосов — следить за выборами можно на портале «Наш выбор». В 2018 г. внедрён сервис «Мобильный избиратель»: пользователь Госуслуг может подать заявление о включении в список избирателей по месту нахождения.",
          text:
            "<p>В 2020 г. реализована система дистанционного электронного голосования — сервис для избирателей, ЦИК, избиркомов, Минцифры, общественных наблюдателей. В его основу легли новейшие решения по информационной безопасности и защите от кибератак. Задача «Ростелекома» — обеспечить возможность безопасного, надёжного и прозрачного голосования онлайн каждому избирателю.</p>",
          quiz: {
            title: "Голосуем онлайн",
            text:
              "«Ростелеком» обеспечивает прозрачный избирательный процесс в России. Мы собрали интересные факты о цифровых выборах, но только часть из них — верные. Сможете угадать какие?",
            items: [
              {
                type: 3,
                question:
                  "С 2012 года «Ростелеком» ведет видеонаблюдение за ходом голосования и подсчета голосов. Трансляцию в режиме реального времени могут видеть все граждане России.",
                answers: [
                  {
                    text: "Верно",
                    correct: true,
                  },
                  {
                    text: "Неверно",
                    correct: false,
                  },
                ],
                correct_text:
                  "С момента открытия избирательного участка <br>и до подписания итоговых протоколов любой житель нашей страны может смотреть трансляции на портале «Наш выбор».",
                wrong_text:
                  "С момента открытия избирательного участка <br>и до подписания итоговых протоколов любой житель нашей страны может смотреть трансляции на портале «Наш выбор».",
              },
              {
                type: 3,
                question:
                  "Благодаря видеонаблюдению на участках появилась возможность стать онлайн-наблюдателем и фиксировать возможные нарушения на участках.",
                answers: [
                  {
                    text: "Верно",
                    correct: false,
                  },
                  {
                    text: "Неверно",
                    correct: true,
                  },
                ],
                correct_text:
                  "Такой возможности не существует. По закону наблюдатели должны присутствовать на избирательных участках.",
                wrong_text:
                  "Такой возможности не существует. По закону наблюдатели должны присутствовать на избирательных участках.",
              },
              {
                type: 3,
                question:
                  "Благодаря сервису «Мобильный избиратель» пользователь может проголосовать на дому, в другом регионе и даже в другой стране.",
                answers: [
                  {
                    text: "Верно",
                    correct: true,
                  },
                  {
                    text: "Неверно",
                    correct: false,
                  },
                ],
                correct_text:
                  "Сервис «Мобильный избиратель» доступен во время проведения избирательных кампаний, и его легко найти <br>в разделе «Мои выборы».",
                wrong_text:
                  "Сервис «Мобильный избиратель» доступен во время проведения избирательных кампаний, и его легко найти <br>в разделе «Мои выборы».",
              },
              {
                type: 3,
                question:
                  "Услуги сервиса «Мобильный избиратель» доступны в личном кабинете Госуслуг. ",
                answers: [
                  {
                    text: "Верно",
                    correct: true,
                  },
                  {
                    text: "Неверно",
                    correct: false,
                  },
                ],
                correct_text:
                  "Он доступен во время проведения избирательных кампаний, и его легко найти в разделе «Мои выборы».",
                wrong_text:
                  "Он доступен во время проведения избирательных кампаний, и его легко найти в разделе «Мои выборы».",
              },
              {
                type: 3,
                question:
                  "Важным нововведением на выборах 2020 года стала система дистанционного электронного голосования (ДЭГ). Участвовать в голосовании дистанционно смогли все жители России.",
                answers: [
                  {
                    text: "Верно",
                    correct: false,
                  },
                  {
                    text: "Неверно",
                    correct: true,
                  },
                ],
                correct_text:
                  "Пилотный проект действовал на двух одномандатных округах в Курской и Ярославской областях.",
                wrong_text:
                  "Пилотный проект действовал на двух одномандатных округах в Курской и Ярославской областях.",
              },
            ],
            success_text:
              "Вашим знаниям можно позавидовать, вы — ходячая энциклопедия. Эрудиция — одно из важных качеств нашего времени, продолжайте в том же духе.",
            fail_text:
              "Чего-то не знать — не страшно, страшнее ничем не интересоваться. В любом случае вы  узнали много новой информации и теперь можете проверить, что из этого вы смогли запомнить.",
          },
        },
        "42-1": {
          type: 9,
          title: "2014. СМОТРИ.ЕГЭ",
          info:
            "На Едином государственном экзамене (ЕГЭ) внедрено видеонаблюдение от «Ростелекома». В школах и других пунктах сдачи экзамена установили первые веб-камеры, сформировали региональные центры обработки информации, где теперь в режиме онлайн наблюдают за процедурой проведения ЕГЭ.",
          text:
            "<p>Ведётся видеонаблюдение за обработкой данных. Создан ситуационно-информационный центр Рособрнадзора в Москве — здесь на видеостене транслируется видео из всех регионов страны. Трансляцию можно посмотреть на портале www.smotriege.ru. Сегодня весь видеопоток онлайн и в записи анализирует нейросеть «Ростелекома». Благодаря этому процедура проведения ЕГЭ стала более прозрачной и обеспечила равные условия для всех участников.</p>",
          game: 5,
        },
        "45-1": {
          type: 9,
          title: "2014. БОЛЬШИЕ ДАННЫЕ",
          info:
            "«Ростелеком» одним из первых сделал ставку на развитие Big Data и в 2014 г. подписал с IBM меморандум, направленный на развитие сотрудничества в области больших данных.",
          text:
            "<p>В 2015 г. «Ростелеком» купил компанию «АЙКУМЕН ИБС» — российского разработчика в области больших данных и начал активно развивать технологии Big Data. В 2020 г. создано совместное предприятие с банком ВТБ — «Платформа больших данных». К 2025 г. компания планирует занять более 10% целевого рынка продуктов больших данных в России. Планируется внедрить платформы обмена Big Data, сервисы дистанционной оценки имущества, прогноза спроса, доступа к облачной Big Data-инфраструктуре, другие инновационные проекты.</p>",
          game: 15,
        },
        "45-2": {
          type: 9,
          title: "2015. УМНЫЙ ГОРОД",
          info:
            "С 2015 г. «Ростелеком» внедряет цифровые решения для создания «Умных городов». С 2019 г. направление «Умный город» масштабировано в «Цифровой регион». Внедряются системы «Безопасный город», «Система 112», «Единая диспетчерская служба ЖКХ», мониторинг окружающей среды, управление ЖКХ, транспортом.",
          text:
            "<p>Монтируются умные остановки, умные опоры, умные контейнеры, системы контроля энергоресурсов, платформы видеонаблюдения (порядка 350 тыс. камер по всей стране). Более чем в 60 регионах построены комплексы фотовидеофиксации нарушений ПДД, почти в 40 субъектах — автоматизированные системы весового и габаритного контроля. Благодаря им на 25% снижена аварийность на дорогах.</p>",
          game: 6,
        },
        "48-1": {
          type: 8,
          title: "2016. ОС «АВРОРА»",
          info:
            "В 2016 г. начата работа над первой отечественной операционной системой «Аврора» и платформой управления мобильными устройствами «Аврора Центр».",
          text:
            '<p>«Аврора» формирует доверенную и безопасную мобильную среду для корпоративных и государственных заказчиков, гарантируя защищённый доступ к информационным системам, позволяет создавать гибкие отраслевые решения под требования заказчика и даёт инструменты для управления корпоративными устройствами. Разработкой и продвижением продукта занимается «Открытая мобильная платформа» (дочерняя компания «Ростелекома»). В 2023 г. «Аврора» признана самой известной российской ОС. Хотите испытать устройства с «Авророй»? Подайте заявку на участие в программе бета-тестирования! <a href="https://auroraos.ru/beta" target="_blank">https://auroraos.ru/beta</a></p>',
        },
        "53-1": {
          type: 9,
          title: "2016. КИБЕРБЕЗОПАСНОСТЬ",
          info:
            "В 2016 г. в «Ростелекоме» начал работу Центр кибербезопасности и защиты, стоящий на страже информационной безопасности.",
          text:
            "<p>Сегодня кибербезопасность более чем 750 организаций (от малого бизнеса до федеральных органов власти) обеспечивает компания «Ростелеком-Солар» — лидер на российском рынке сервисов информационной безопасности. Собственные уникальные продукты позволяют проводить мониторинг и анализ угроз, предотвращать вторжения и отстраивать высокоэффективные системы защиты. Для повышения киберустойчивости страны «Ростелеком» развивает Национальный киберполигон. Только в 2022 г. число кибератак в России выросло на 80% — все они были успешно отражены.</p>",
          game: 7,
          mini_title: true,
          small_title_mobile: true,
          is_disable_music: true,
        },
        "56-1": {
          type: 3,
          title: "2017. ИСКУССТВЕННЫЙ ИНТЕЛЛЕКТ",
          info:
            "В 2017 г. «Ростелеком» представил цифровой сервис с применением ИИ — автоматизированную самообучаемую систему для глубокого анализа трендов любых отраслей. Позднее в компании была принята стратегия по ИИ.",
          text:
            "<p>Сегодня каждый проект «Ростелекома» предполагает прикладное применение искусственного интеллекта: чат-боты, голосовые помощники, речевая аналитика, системы поддержки принятия решений и др. Технологии ИИ интегрированы в проекты, связанные с умными городами, кибербезопасностью, обслуживанием оборудования. В 2023 г. в компании образован департамент искусственного интеллекта и управления данными. Впереди у компании новые яркие проекты, связанные с развитием ИИ.</p>",
          quiz: {
            title: "Великий и ужасный ИИ",
            text:
              "Наверняка вы уже спорили с друзьями на темы, касающиеся искусственного интеллекта. Как вы считаете: ИИ несет опасность или пользу? Давайте разберемся вместе. С вас — честные ответы, с нас — научные объяснения. ",
            items: [
              {
                type: 3,
                question: "Займет ли ИИ ваше рабочее место?",
                answers: [
                  {
                    text: "Да",
                    correct: false,
                  },
                  {
                    text: "Нет",
                    correct: true,
                  },
                ],
                correct_text:
                  "Технологии ИИ изначально были созданы, чтобы автоматизировать многие ручные процессы, поэтому нейросети действительно могут заменить человеческий труд там, где нужна автоматизация: подсчеты, аналитика данных, копирование и распространение информации, создание контента по шаблонам, выполнение технических задач и т.д. Заменить творческих специалистов или узкопрофильных сотрудников ИИ не сможет. Кроме того, использование ИИ приводит к появлению новых специальностей — операторов нейросетей, авторов технических заданий и др.",
                wrong_text:
                  "Технологии ИИ изначально были созданы, чтобы автоматизировать многие ручные процессы, поэтому нейросети действительно могут заменить человеческий труд там, где нужна автоматизация: подсчеты, аналитика данных, копирование и распространение информации, создание контента по шаблонам, выполнение технических задач и т.д. Заменить творческих специалистов или узкопрофильных сотрудников ИИ не сможет. Кроме того, использование ИИ приводит к появлению новых специальностей — операторов нейросетей, авторов технических заданий и др.",
              },
              {
                type: 3,
                question:
                  "Можно ли при помощи технологий ИИ украсть ваши персональные данные?",
                answers: [
                  {
                    text: "Да",
                    correct: true,
                  },
                  {
                    text: "Нет",
                    correct: false,
                  },
                ],
                correct_text:
                  "Нейросети хранят данные запросов. Чтобы личная информация не попала в руки киберпреступников, не раскрывайте в общении с ИИ адреса, контакты, историю болезней, персональные данные. Обращайте внимание на адреса ресурсов, с помощью которых оплачиваете товары или услуги: при помощи нейросетей злоумышленники могут генерировать максимально похожие сайты — выдать их способна только разница в написании url. К счастью, сегодня специалисты «Ростелекома» серьезно занимаются вопросами кибербезопасности, и появилось множество цифровых продуктов, которые помогут защитить ваши устройства от фишинга и других приемов злоумышленников.)",
                wrong_text:
                  "Нейросети хранят данные запросов. Чтобы личная информация не попала в руки киберпреступников, не раскрывайте в общении с ИИ адреса, контакты, историю болезней, персональные данные. Обращайте внимание на адреса ресурсов, с помощью которых оплачиваете товары или услуги: при помощи нейросетей злоумышленники могут генерировать максимально похожие сайты — выдать их способна только разница в написании url. К счастью, сегодня специалисты «Ростелекома» серьезно занимаются вопросами кибербезопасности, и появилось множество цифровых продуктов, которые помогут защитить ваши устройства от фишинга и других приемов злоумышленников.)",
              },
              {
                type: 3,
                question:
                  " Появится ли у ИИ сознание и сможет ли он действовать без контроля человека? ",
                answers: [
                  {
                    text: "Да",
                    correct: false,
                  },
                  {
                    text: "Нет",
                    correct: true,
                  },
                ],
                correct_text:
                  "Основная суть ИИ — это возможность быстро обрабатывать большие объемы информации. Такая производительность неподвластна объемам человеческого мозга. Но у ИИ нет творческого мышления: он может принимать непрогнозируемые решения, действовать спонтанно, эмпатично или креативно. Поэтому действовать осознанно и без контроля человека искусственный интеллект не сможет. Во всяком случае пока.",
                wrong_text:
                  "Основная суть ИИ — это возможность быстро обрабатывать большие объемы информации. Такая производительность неподвластна объемам человеческого мозга. Но у ИИ нет творческого мышления: он может принимать непрогнозируемые решения, действовать спонтанно, эмпатично или креативно. Поэтому действовать осознанно и без контроля человека искусственный интеллект не сможет. Во всяком случае пока.",
              },
            ],
            success_text:
              "Вашим знаниям можно позавидовать, вы — ходячая энциклопедия. Эрудиция — одно из важных качеств нашего времени, продолжайте в том же духе.",
            fail_text:
              "Чего-то не знать — не страшно, страшнее ничем не интересоваться. В любом случае вы  узнали много новой информации и теперь можете проверить, что из этого вы смогли запомнить.",
          },
          small_title: true,
          small_title_mobile: true,
        },
        "57-1": {
          type: 9,
          title: "2018. ЕДИНАЯ БИОМЕТРИЧЕСКАЯ СИСТЕМА",
          info:
            "Запущена «Ростелекомом» в 2018 г., а в 2021 г. получила статус государственной. Позволяет идентифицировать гражданина по уникальным биометрическим характеристикам. По голосу и лицу с точностью до 99,9% определяется реальный человек, а не имитация его данных.",
          text:
            "<p>После регистрации в системе пользователь может получать онлайн услуги любого банка, работающего с системой. Достаточно ввести логин и пароль и произнести сгенерированную короткую числовую последовательность, глядя в камеру. Точность идентификации обеспечивают алгоритмы ведущих российских разработчиков программного обеспечения в области биометрии.</p>",
          game: 8,
          mini_title: true,
          small_title_mobile: true,
          is_disable_music: true,
        },
        "59-1": {
          type: 9,
          title: "2018. WINK",
          info:
            "«Ростелеком» презентовал новый цифровой сервис Wink, который объединил преимущества интерактивного телевидения (более 300 телеканалов) и онлайн-кинотеатра с отличной коллекцией кино, сериалов, мультфильмов, концертов и другого контента на любой вкус (более 60 тысяч позиций). В сервисе имеются также аудиокниги и караоке.",
          text:
            "<p>Wink доступен на экранах домашнего телевизора (с помощью ТВ-приставки, приложений в Smart TV, Apple TV и Android TV), смартфоне или планшете (с помощью мобильных приложений для Android и iOS), ноутбуке и компьютере (на видеопортале wink.ru). Сегодня Wink входит в топ-3 российских онлайн-кинотеатров по числу подписчиков — в 2023 г. их число превысило 11,5 млн.</p>",
          game: 9,
        },
        "61-1": {
          type: 3,
          title: "2019. РОСТЕЛЕКОМ ЛИЦЕЙ",
          info:
            "Начала работу новая образовательная онлайн-платформа «Ростелекома». На ней можно найти уроки по всей школьной программе (с 1 по 11 класс), курсы по развитию, разработанные преподавателями ведущих вузов России.",
          text:
            "<p> К каждому занятию прилагаются тест и тренажёр на закрепление материала, интерактивные подсказки и советы. В разделе «Развивающие курсы» собраны курсы по профориентации, обучающие мультфильмы, тренажёры на развитие мышления и др. «Лицей» помогает закрыть пробелы в знаниях и подготовиться к ЕГЭ и ОГЭ, показывает родителям прогресс ученика. Образовательный и развивающий контент доступен пользователям по подписке.</p>",
          quiz: {
            title: "Проверь свои знания",
            text:
              "Вы внезапно оказались в выпускном классе. Давайте проверим, сможете ли вы решить задания базового уровня ЕГЭ по разным предметам?",
            items: [
              {
                type: 2,
                question:
                  "Начнем с математики. На шторы сдали родители 13 учеников, что составляет треть от общего числа учащихся в классе. Сколько родителей проигнорировали просьбу классного руководителя?",
                answers: [
                  {
                    text: "39",
                    correct: false,
                  },
                  {
                    text: "26",
                    correct: true,
                  },
                  {
                    text: "30",
                    correct: false,
                  },
                ],
                correct_text:
                  " Всего в классе 39 человек, и родители 26 из них, видимо, решили, что собирать на шторы каждый год — перебор.",
                wrong_text:
                  " Всего в классе 39 человек, и родители 26 из них, видимо, решили, что собирать на шторы каждый год — перебор.",
              },
              {
                type: 4,
                question:
                  "Переходим к русскому языку. В каком из слов допущена ошибка в постановке ударения?",
                answers: [
                  {
                    text: "КрасИвее",
                    correct: false,
                  },
                  {
                    text: "ОзлОбить",
                    correct: false,
                  },
                  {
                    text: "ПрибЫло",
                    correct: true,
                  },
                  {
                    text: "ТОрты",
                    correct: false,
                  },
                ],
                correct_text:
                  "Ударение в слове «прИбыло» падает на первый слог. Теперь можете смело использовать фразу «нашего полку прибыло», когда к вашей компании присоединяются друзья.",
                wrong_text:
                  "Ударение в слове «прИбыло» падает на первый слог. Теперь можете смело использовать фразу «нашего полку прибыло», когда к вашей компании присоединяются друзья.",
              },
              {
                type: 4,
                question:
                  " Готовы к обществознанию? В перечне качеств, присущих человеку, только один не имеет социальную природу. <br/>Отметьте его.",
                answers: [
                  {
                    text: "Инициативность",
                    correct: false,
                  },
                  {
                    text: "Темперамент",
                    correct: true,
                  },
                  {
                    text: "Ответственность",
                    correct: false,
                  },
                  {
                    text: "Дисциплинированность",
                    correct: false,
                  },
                ],
                correct_text:
                  "У темперамента биологический фундамент. Если вы родились холериком, придется с этим смириться — и не только вам, но и вашим близким.",
                wrong_text:
                  "У темперамента биологический фундамент. Если вы родились холериком, придется с этим смириться — и не только вам, но и вашим близким.",
              },
              {
                type: 2,
                question:
                  "Кажется, все слишком легко? Держитесь! На очереди великая русская литература. Как Гоголь определил жанр «Мертвых душ»?",
                answers: [
                  {
                    text: "Роман",
                    correct: false,
                  },
                  {
                    text: "Поэма",
                    correct: true,
                  },
                  {
                    text: "Эпопея",
                    correct: false,
                  },
                ],
                correct_text:
                  "Хотя «Мертвые души» — прозаическое произведение, сам Гоголь отнес его к поэме. С автором лучше не спорить.",
                wrong_text:
                  "Хотя «Мертвые души» — прозаическое произведение, сам Гоголь отнес его к поэме. С автором лучше не спорить.",
              },
              {
                type: 1,
                question:
                  "Переходим к истории. Если расположить события в хронологическом порядке, то какое из них было первым?",
                answers: [
                  {
                    text: "Крымская война",
                    correct: false,
                  },
                  {
                    text: "Реформа патриарха Никона",
                    correct: false,
                  },
                  {
                    text: "Падение Византийской империи",
                    correct: true,
                  },
                ],
                correct_text:
                  "Падение Византийской империи произошло в 1453 году, церковная реформа была проведена в 1653 году, а Крымская война длилась с 1853 по 1856 год.",
                wrong_text:
                  "Падение Византийской империи произошло в 1453 году, церковная реформа была проведена в 1653 году, а Крымская война длилась с 1853 по 1856 год.",
              },
            ],
            success_text:
              "Вашим знаниям можно позавидовать, вы — ходячая энциклопедия. Эрудиция — одно из важных качеств нашего времени, продолжайте в том же духе.",
            fail_text:
              "Чего-то не знать — не страшно, страшнее ничем не интересоваться. В любом случае вы  узнали много новой информации и теперь можете проверить, что из этого вы смогли запомнить.",
          },
          small_title: true,
        },
        "63-1": {
          type: 9,
          title: "2020. РОСТЕЛЕКОМ КЛЮЧ",
          info:
            "«Ростелеком» начал внедрять экосистему сервисов для жителей многоквартирных домов и управляющих компаний. Платформа объединила комплекс цифровых решений для обеспечения безопасности и управления домами: умные домофоны, умные шлагбаумы, видеонаблюдение, умные приборы учёта, облачные СКУД (контроль и управление доступом).",
          text:
            "<p>С помощью приложения жители могут управлять домофоном, калиткой, просматривать трансляции с камер. Видеоаналитика строится на базе нейросетей — пользователь может самостоятельно задавать параметры слежения за объектами. Сегодня «Ключ» есть более чем у миллиона россиян. Это полностью российская разработка, созданная с нуля командой «Ростелекома».</p>",
          game: 16,
          small_title: true,
        },
        "64-1": {
          type: 2,
          title: "2020. ГОЛОСОВОЙ ПОМОЩНИК МАРУСЯ",
          info:
            "«Ростелеком» и Mail.ru Group объявили о стратегическом партнёрстве в развитии голосового управления цифровыми сервисами, включающего использование технологий искусственного интеллекта и интернета вещей.",
          text:
            "<p>В этом же году в продажу поступила умная колонка Капсула с Марусей. Она рассказывает о погоде, отвечает на вопросы, играет, читает сказки, включает треки, управляет каждым элементом системы умного дома. Марусю зовут, когда нужна помощь с делами или наоборот, когда хочется отдохнуть. А ещё её обожают дети. Кстати, благодаря функции «Узнавать детей» Маруся по голосу распознаёт, что общается с ребёнком, и не говорит с ним на «взрослые» темы.</p>",
          game: 13,
          viewer: 7,
          is_disable_music: true,
        },
        "64-2": {
          type: 9,
          title: "2022. УСТРАНЕНИЕ ЦИФРОВОГО НЕРАВЕНСТВА",
          info:
            "«Ростелеком» завершил строительство подводной волоконно-оптической линии передачи от Петропавловска-Камчатского до Анадыря. Сложнейший технический проект мирового уровня — по дну моря проложено свыше 2,1 тыс. км сетей!",
          text:
            "<p>Чукотка была последним регионом России, не имевшим подключения по оптике. Теперь жителям доступны все современные онлайн-сервисы. С 2014 г. благодаря «Ростелекому» по федеральной программе «Устранение цифрового неравенства» мобильная связь и высокоскоростной интернет пришли в самые отдалённые уголки России. Главная цель компании — предоставление равных цифровых возможностей всем гражданам нашей страны, где бы они ни жили.</p>",
          game: 10,
          small_title: true,
          small_title_mobile: true,
        },
        "66-1": {
          type: 12,
          title: "ПРИМЕРЬ РОЛЬ ФУТУРОЛОГА",
          info: "",
          text: "",
          quiz: {
            title: "Примерь роль футуролога",
            text:
              "Какие-то технологии, предсказанные фантастами, уже можно лицезреть во многих домах, а какие-то и сейчас кажутся плодом бурного воображения. Примерьте на себя роль футуролога, чтобы понять, как вы разбираетесь в способах связи настоящего и будущего.",
            image: 1,
            items: [
              {
                type: 1,
                question:
                  "Каким способом связи часто пользуются герои фантастических фильмов и книг XX века?",
                answers: [
                  {
                    text: "Мобильная связь",
                    correct: false,
                  },
                  {
                    text: "Телепорт",
                    correct: true,
                  },
                  {
                    text: "Гипносвязь",
                    correct: false,
                  },
                ],
                correct_text:
                  "Почему-то фантастам не пришла в голову идея мобильной связи. Зато они придумали телепорты. Не поспоришь, что выяснять отношения так удобнее, чем по телефону.",
                wrong_text:
                  "Почему-то фантастам не пришла в голову идея мобильной связи. Зато они придумали телепорты. Не поспоришь, что выяснять отношения так удобнее, чем по телефону.",
                image: 2,
              },
              {
                type: 3,
                question:
                  "В научной фантастике для связи часто используют голограммы — трехмерные оптические клоны объектов. А возможно ли их появление в реальности?",
                answers: [
                  {
                    text: "Да",
                    correct: true,
                  },
                  {
                    text: "Нет",
                    correct: false,
                  },
                ],
                correct_text:
                  "Специальные машины для «голопортации» и очки смешанной реальности уже сейчас позволяют вести голографическую коммуникацию. Только, кажется, дешевле дождаться телепортации, чем купить устройство для голографического общения.",
                wrong_text:
                  "Специальные машины для «голопортации» и очки смешанной реальности уже сейчас позволяют вести голографическую коммуникацию. Только, кажется, дешевле дождаться телепортации, чем купить устройство для голографического общения.",
                image: 3,
              },
              {
                type: 3,
                question:
                  "В ближайшем будущем появятся устройства, которые позволят переводить речь собеседника прямо во время общения и даже читать его мысли. Верно ли это?",
                answers: [
                  {
                    text: "Да",
                    correct: false,
                  },
                  {
                    text: "Нет",
                    correct: true,
                  },
                ],
                correct_text:
                  "К сожалению, повод произнести фразу: «Алиса, миелофон у меня» появится нескоро. Люди все еще справляются с синхронным переводом лучше, чем машины.",
                wrong_text:
                  "К сожалению, повод произнести фразу: «Алиса, миелофон у меня» появится нескоро. Люди все еще справляются с синхронным переводом лучше, чем машины.",
                image: 4,
              },
              {
                type: 1,
                question:
                  "У нескольких фантастов в произведениях появляется ансибл. Что умеет это устройство?",
                answers: [
                  {
                    text: "Передавать ароматы и вкусы еды в рекламе",
                    correct: false,
                  },
                  {
                    text: "Мгновенно отправлять сообщения со сверхсветовой скоростью",
                    correct: true,
                  },
                  {
                    text: "Позволять перемещаться во времени и пространстве",
                    correct: false,
                  },
                ],
                correct_text:
                  "Впервые устройство связи со сверхсветовой скоростью появилось в книгах Урсулы Ле Гуин, а потом разошлось по мирам писателей-фантастов как инновационная технология. Ученые считают эту технологию пока невозможной.",
                wrong_text:
                  "Впервые устройство связи со сверхсветовой скоростью появилось в книгах Урсулы Ле Гуин, а потом разошлось по мирам писателей-фантастов как инновационная технология. Ученые считают эту технологию пока невозможной.",
                image: 5,
              },
              {
                type: 3,
                question:
                  "Ученые ведут разработки умной кожи, с помощью которой можно будет записывать свои тактильные ощущения, загружать их в базу данных и передавать для скачивания. Верите?",
                answers: [
                  {
                    text: "Да",
                    correct: false,
                  },
                  {
                    text: "Нет",
                    correct: true,
                  },
                ],
                correct_text:
                  "Это прогноз футуролога Яна Пирсона. Он предсказывает, что технология появится к 2050 году. А до этого придется верить друзьям на слово, что отдых на Балтийском море удался и вода была теплой.",
                wrong_text:
                  "Это прогноз футуролога Яна Пирсона. Он предсказывает, что технология появится к 2050 году. А до этого придется верить друзьям на слово, что отдых на Балтийском море удался и вода была теплой.",
                image: 6,
              },
            ],
            success_text:
              "Ваши знания и предсказательные  способности действительно впечатляют.  Продолжайте исследовать будущее и делиться  своими уникальными видениями с миром",
            fail_text:
              "Это один из шагов на пути к успеху! Продолжайте учиться и исследовать тему будущего, и вы обязательно достигнете своих целей.",
          },
          small_title: true,
        },
        "66-2": {
          type: 13,
          title: "ВОПРОСЫ НЕЙРОСЕТИ БУДУЩЕГО",
          info: "",
          text: "",
          game: 14,
        },
        "68-1": {
          type: 17,
          title: "Новогодний эфир",
          info: "23 декабря <br>В 9:00 по московскому времени!",
          text:
            "<p class='higlighted-subtitle'>Вас ждут:</p><ul><li>увлекательные интервью  с топ-менеджерами и коллегами; </li><li>игры и интерактивы со зрителями; </li><li>секретный звездный гость; </li><li>и, конечно, розыгрыш марафона желаний «Время чудес»! Президент компании Михаил Осеевский объявит счастливчиков, чьи желания будут исполнены.</li></ul><p>Не пропустите!</p>",
          small_title: true,
          is_disable_music: true,
        },
        "99-1": {
          type: 18,
          title: "Добро пожаловать!",
          info: "Госуслуги празднуют свое 15-ти летие!",
          text:
            "<p>За это время, портал прошел разные этапы развития: от запуска в 2009 году, до создания своего уникального фирменного стиля, запуска цифрового помощника робота Макса, мобильных приложений  «Госключ», «Госуслуги Авто», «Госуслуги Культура» и «Госуслуги Решаем вместе», а также запуска визуального конструктора, который позволяет регионам и ведомствам легко и быстро «собирать» свои сервисы и выводить их на портал. <br> В 2024 году, аудитория портала превысила 110 млн человек, а средняя ежедневная аудитория составляет 14 миллионов пользователей!</p><p>В честь юбилея, на нашем новогоднем изображении мы спрятали 11 героев Госуслуг. Найдите всех, чтобы узнать лайфхаки, которые помогут вам в повседневной жизни!</p>",
          small_title: true,
          small_title_mobile: true,
          is_disable_music: true,
        },
      },
    };
  },
  mounted() {
    window.addEventListener("scroll", this.scrollHandler);
    if (window.innerWidth > 999) {
      this.l = new Lenis({
        lerp: 0.1,
      });
      requestAnimationFrame(this.raf);
    } else {
      addScrollableSelector(".popup");
    }
    window.scrollTo(0, 0);
    window.addEventListener("resize", this.appHeight);
    this.appHeight();
    if (this.$route.query.pin) {
      this.start();
    }
  },
  beforeDestroy() {
    clearInterval(this.scrollInterval);
  },
  methods: {
    start() {
      this.sendEvent("enter");
      this.welcome = false;
      setTimeout(() => {
        this.playMusic();
        if (this.$route.query.pin == "history") {
          window.scrollTo({
            top: 33000,
            behavior: "smooth",
          });
          setTimeout(() => {
            this.openModal("1-4");
          }, 1000);
        } else if (this.$route.query.pin == "generation") {
          window.scrollTo({
            top: 33000,
            behavior: "smooth",
          });
          setTimeout(() => {
            this.openModal("1-2");
          }, 1000);
        } else if (this.$route.query.pin == "technology") {
          window.scrollTo({
            top: 33000,
            behavior: "smooth",
          });
          setTimeout(() => {
            this.openModal("1-3");
          }, 1000);
        } else if (this.$route.query.pin == "ether") {
          window.scrollTo({
            top: 67100,
            behavior: "smooth",
          });
          setTimeout(() => {
            this.openModal("68-1");
          }, 1000);
        } else if (this.$route.query.pin == "game") {
          window.scrollTo({
            top: 67100,
            behavior: "smooth",
          });
          setTimeout(() => {
            this.openModal("99-1");
          }, 1000);
        } else if (this.$route.query.pin == "postcard") {
          window.scrollTo({
            top: 67100,
            behavior: "smooth",
          });
        } else {
          window.scrollTo({
            top: 199,
            behavior: "smooth",
          });
        }
      }, 200);
    },
    playMusic() {
      this.sound = true;
    },
    justClick(e) {
      if (!e.target.classList.contains("js-toggle-scroll")) {
        this.autoScroll = false;
      }
    },
    scrollHandler() {
      const { documentElement, body } = document;
      let windowScroll = body.scrollTop || documentElement.scrollTop;
      let height = documentElement.scrollHeight - documentElement.clientHeight;
      if (height == 0) {
        windowScroll = 100;
        height = 10000;
      }
      let progressNumber = (windowScroll / height) * 100;
      if (progressNumber > 99 && this.autoScroll) {
        this.autoScroll = false;
        this.fade = true;
        setTimeout(() => {
          window.scrollTo(0, 1);
        }, 1500);
        setTimeout(() => {
          this.fade = false;
          this.toggleAutoScroll();
        }, 1000);
      }
    },
    toggleAutoScroll() {
      this.autoScroll = !this.autoScroll;
      if (this.autoScroll) {
        this.autoScrollFunction();
      }
    },
    stopautoscroll() {
      this.autoScroll = false;
    },
    autoScrollFunction() {
      if (!this.autoScroll) {
        return; // Остановить прокрутку, если autoScroll выключен
      }
      window.scrollTo(0, window.scrollY + 4);
      // Повторяем функцию через requestAnimationFrame для плавной анимации
      requestAnimationFrame(this.autoScrollFunction);
    },
    toggleSound() {
      this.sound = !this.sound;
    },
    closeBurger() {
      if (window.innerWidth > 999) {
        this.l.start();
      }
      scrollLock.enablePageScroll();
    },
    openBurger() {
      if (window.innerWidth > 999) {
        this.l.stop();
      }
      scrollLock.disablePageScroll();
    },
    appHeight() {
      const doc = document.documentElement;
      doc.style.setProperty("--app-height", `${window.innerHeight}px`);
    },
    raf(time) {
      this.l.raf(time);
      requestAnimationFrame(this.raf);
    },
    openModal(id) {
      //к одной модалке привязано до трех точек - указываем их id
      if (id == "2-2" || id == "3-1" || id == "1-1") {
        id = "1-1";
        this.sendEvent("pigeon");
      }
      if (id == "5-1" || id == "6-1" || id == "7-1" || id == "4-1") {
        id = "4-1";
        this.sendEvent("telegraf");
      }
      if (id == "9-1" || id == "10-1" || id == "8-1") {
        id = "8-1";
        this.sendEvent("phone");
      }
      if (id == "14-1" || id == "13-1") {
        id = "13-1";
        this.sendEvent("payphone");
      }
      if (id == "17-1" || id == "15-1" || id == "16-1") {
        id = "16-1";
        this.sendEvent("radio");
      }
      if (id == "20-1" || id == "19-2" || id == "21-1") {
        id = "19-2";
        this.sendEvent("Leningrad");
      }
      if (id == "23-1" || id == "22-1") {
        id = "22-1";
        this.sendEvent("pager");
      }
      if (id == "18-2" || id == "18-1" || id == "19-1") {
        id = "19-1";
        this.sendEvent("ATC");
      }
      if (id == "23-2" || id == "24-1" || id == "22-2") {
        id = "22-2";
        this.sendEvent("Sputnik");
      }
      if (id == "26-1" || id == "27-1" || id == "25-1") {
        id = "25-1";
        this.sendEvent("communication");
      }
      if (id == "28-2") {
        id = "28-1";
        this.sendEvent("internet");
      }
      if (id == "29-1" || id == "28-1") {
        id = "28-1";
        this.sendEvent("internet");
      }
      if (id == "29-2" || id == "30-1") {
        id = "30-1";
        this.sendEvent("mobile");
      }
      if (id == "33-1" || id == "34-1" || id == "32-1") {
        id = "32-1";
        this.sendEvent("www");
      }
      if (id == "36-1" || id == "35-1") {
        id = "35-1";
        this.sendEvent("gosuslugi");
      }
      if (id == "36-2" || id == "35-2") {
        id = "35-2";
        this.sendEvent("cloud");
      }
      if (id == "38-1" || id == "37-1") {
        id = "37-1";
        this.sendEvent("elections");
      }
      if (id == "43-1" || id == "44-1" || id == "42-1") {
        id = "42-1";
        this.sendEvent("school");
      }
      if (id == "46-1" || id == "45-1") {
        id = "45-1";
        this.sendEvent("data");
      }
      if (id == "46-2" || id == "47-1" || id == "47-2" || id == "45-2") {
        id = "45-2";
        this.sendEvent("city");
      }
      if (id == "49-1" || id == "50-1" || id == "51-1" || id == "52-1" || id == "48-1") {
        id = "48-1";
        this.sendEvent("Avrora");
      }
      if (id == "55-2" || id == "56-1") {
        id = "56-1";
        this.sendEvent("intelligence");
      }
      if (id == "54-1" || id == "55-1" || id == "53-1") {
        id = "53-1";
        this.sendEvent("security");
      }
      if (id == "58-1" || id == "57-1") {
        id = "57-1";
        this.sendEvent("biometrics");
      }
      if (id == "63-1" || id == "64-2") {
        id = "64-1";
        this.sendEvent("speaker");
      }
      if (id == "63-2" || id == "62-2") {
        id = "63-1";
        this.sendEvent("room");
      }
      if (id == "60-1" || id == "59-1") {
        id = "59-1";
        this.sendEvent("WINK");
      }
      if (id == "62-1" || id == "61-1") {
        id = "61-1";
        this.sendEvent("lyceum");
      }
      if (id == "65-1" || id == "64-2" || id == "63-3" || id == "64-3") {
        id = "64-2";
        this.sendEvent("router");
      }
      if (id == "70-1" || (id == "69-2" && window.innerWidth < 1000)) {
        id = "66-1";
        this.sendEvent("futurologist");
      }
      if (id == "70-2" || (id == "69-3" && window.innerWidth < 1000)) {
        id = "66-2";
        this.sendEvent("networks");
      }
      if (id == "68-1" || id == "69-1" || id == "67-1") {
        id = "68-1";
        this.sendEvent("Live broadcast");
      }
      if (id == "70-1") {
        id = "69-1";
        // this.sendEvent("ether moa");
      }
      if (id == "68-3" || id == "67-3" || (id == "69-3" && window.innerWidth > 1000)) {
        id = "99-1";
        this.sendEvent("gosuslugi2025");
      }
      console.log(id);
      this.active = id;
      if (id == "13-2" || id == "14-2" || id == "15-2") {
        window.open(
          "https://%D1%81%D0%B2%D1%8F%D0%B7%D1%8C%D0%B2%D1%80%D0%B5%D0%BC%D0%B5%D0%BD.%D1%80%D0%BE%D1%81%D1%82%D0%B5%D0%BB%D0%B5%D0%BA%D0%BE%D0%BC.%D1%80%D1%84/ar/",
          "_blank"
        );
      } else if (
        id == "68-2" ||
        id == "67-2" ||
        (id == "69-2" && window.innerWidth > 1000)
      ) {
        window.open("https://t.me/rtk_ny_cards_bot", "_blank");
      } else {
        this.modal = true;
        scrollLock.disablePageScroll();
        if (window.innerWidth > 999) {
          this.l.stop();
        }
      }
    },
    closeModal() {
      this.modal = false;
      this.active = null;
      if (this.sound == true) {
        this.sound = false;
        setTimeout(() => {
          this.sound = true;
        }, 100);
      }
      if (window.innerWidth > 999) {
        this.l.start();
      }
      scrollLock.enablePageScroll();
    },
  },
};
</script>

<style lang="sass">
.white-screen
	background-color: #fff
	position: fixed
	top: 0
	left: 0
	width: 100vw
	height: 100vh
	z-index: 999999999

.main
	width: 100%
	height: 100vh
	height: var(--app-height)
	position: relative
	overflow: hidden
	&.active
		height: 70613px
		@media screen and (max-width: 1000px)
			height: 70302px !important
.interface__sound
	z-index: 10
</style>
