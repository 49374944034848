<template>
  <div class="fgame-wrapper">
    <audio ref="fgameMusic" src="@/assets/sounds/aac/fgame.aac" loop></audio>
    <div class="fgame__icon" @click="toggleSound">
      <img
        :src="
          isSoundOn
            ? require('../assets/img/find-game/sound.svg')
            : require('../assets/img/find-game/sound-off.svg')
        "
      />
    </div>
    <div class="fgame">
      <div id="scene">
        <div class="fgame__bg">
          <img src="../assets/img/find-game/game.webp" alt="" />
        </div>
        <div class="fgame-elements">
          <div
            :class="`fgame-el fgame-el--${i}`"
            v-for="i in total"
            :key="i"
            @click="open(i)"
            @touchstart="onTouchStart"
            @touchend="handleTouchEnd($event, i)"
          >
            <img :src="require(`../assets/img/find-game/elements/${i}.png`)" alt="" />
            <img
              class="invisible"
              :class="{ active: founded.includes(i) }"
              :src="require(`../assets/img/find-game/elements/${i}-active.png`)"
              alt=""
            />
          </div>
        </div>
      </div>
      <div v-if="showIntro" class="fgame__intro">
        <img src="../assets/img/find-game/cursor.png" alt="" />
        <div class="fgame__text">
          Зажмите курсор и перемещайте<br />
          иллюстрацию, чтобы найти героев
        </div>
        <div class="fgame__button">
          <a href="javascript:void(0)" @click="start" @touchend="start">понятно</a>
        </div>
      </div>
      <div v-if="gameFinished" class="fgame__overlay">
        <div class="fgame__finished">
          <div class="fgame__finished-text">Все герои найдены</div>
          <div class="fgame__finished-button">
            <a
              href="javascript:void(0)"
              @click="$emit('step', 14)"
              @touchstart="$emit('step', 14)"
              >Завершить</a
            >
          </div>
        </div>
      </div>
    </div>
    <div v-if="started && founded.length < total" class="fgame-panel__text-mobile">
      Героев найдено: {{ founded.length }}/{{ total }}
    </div>
    <div class="fgame-panel" v-if="started">
      <div v-if="started && founded.length < total" class="fgame-panel__text">
        Героев найдено: {{ founded.length }}/{{ total }}
      </div>
      <div class="fgame-panel__elements">
        <div class="swiper swiper-els">
          <div class="swiper-wrapper">
            <div class="swiper-slide" v-for="i in total" :key="i">
              <img
                :src="
                  require(`../assets/img/find-game/elements/${
                    !founded.includes(i) ? 'shadow-' : ''
                  }${i}.png`)
                "
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
      <div class="fgame-panel__arrows">
        <div
          class="fgame-panel__arrows-left js-panel-slider-prev"
          @click="slider.slidePrev()"
        >
          <img src="../assets/img/find-game/arrow.svg" alt="" />
        </div>
        <div
          class="fgame-panel__arrows-right js-panel-slider-next"
          @click="slider.slideNext()"
        >
          <img src="../assets/img/find-game/arrow.svg" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Swiper from "swiper";
import "swiper/swiper-bundle.css";
import panzoom from "panzoom";
export default {
  name: "Fgame",
  data() {
    return {
      showIntro: true,
      founded: [],
      total: 11,
      started: false,
      isSoundOn: true,
      isDragging: false,
      gameFinished: false,
      touchStartX: 0,
      touchStartY: 0,
      moveThreshold: 10,
      slider: null,
    };
  },
  mounted() {
    let element = document.querySelector("#scene");
    let panzoomInstance = panzoom(element, {
      maxZoom: 0.5,
      minZoom: 0.5,
      initialZoom: 0.5,
      bounds: true,
      boundsPadding: 1,
      transformOrigin: { x: 0.5, y: 0.5 },
    });
    panzoomInstance.on("panstart", () => {
      this.isDragging = true;
    });

    panzoomInstance.on("panend", () => {
      setTimeout(() => {
        this.isDragging = false;
      }, 500);
    });
    if (window.innerWidth > 1000) {
      this.$refs.fgameMusic.play();
    } else {
      this.isSoundOn = false;
    }
    document.addEventListener("visibilitychange", this.handleVisibilityChange);
  },
  methods: {
    onTouchStart(e) {
      const touch = e.changedTouches[0];
      this.touchStartX = touch.clientX;
      this.touchStartY = touch.clientY;
    },
    handleTouchEnd(e, i) {
      if (this.isDragging) return;
      const touch = e.changedTouches[0];
      const diffX = Math.abs(touch.clientX - this.touchStartX);
      const diffY = Math.abs(touch.clientY - this.touchStartY);

      if (diffX < this.moveThreshold && diffY < this.moveThreshold) {
        e.preventDefault();
        this.open(i);
      }
    },
    handleVisibilityChange() {
      if (document.hidden) {
        this.$refs.fgameMusic.pause();
      } else {
        this.$refs.fgameMusic.play();
      }
    },
    start() {
      this.started = true;
      this.showIntro = false;
      setTimeout(() => {
        this.slider = new Swiper(".swiper-els", {
          direction: "horizontal",
          slidesPerView: "auto",
          loop: false,
          freeMode: true,
        });
        let element = document.querySelector("#scene");
        let panzoomInstance = panzoom(element, {
          maxZoom: 0.5,
          minZoom: 0.5,
          initialZoom: 0.5,
          bounds: true,
          boundsPadding: 1,
          transformOrigin: { x: 0.5, y: 0.5 },
        });
        panzoomInstance.on("panstart", () => {
          this.isDragging = true;
        });

        panzoomInstance.on("panend", () => {
          setTimeout(() => {
            this.isDragging = false;
          }, 400);
        });
      }, 100);
    },
    open(i) {
      if (this.isDragging) return;
      this.$emit("step", i + 2);
      console.log(i + 2);
      if (!this.founded.includes(i)) {
        this.founded.push(i);
        if (this.founded.length === this.total) {
          this.finishGame();
        }
      }
    },
    finishGame() {
      this.gameFinished = true;
    },
    handleTouch(e, i) {
      e.preventDefault();
      setTimeout(() => {
        if (this.isDragging) return;
        this.open(i);
      }, 500);
    },
    toggleSound() {
      this.isSoundOn = !this.isSoundOn;
      this.$refs.fgameMusic.paused
        ? this.$refs.fgameMusic.play()
        : this.$refs.fgameMusic.pause();
    },
  },
};
</script>

<style lang="sass" scoped>
@import '@/assets/sass/functions.sass'
.fgame
    width: 100%
    height: 100%
    position: relative
    scrollbar-width: none
    @media screen and (max-width: 1000px)
      height: vwm(420px)
      overflow: auto
    &-wrapper
        width: 100%
        height: 100%
        position: relative
    &::webkit-scrollbar
        display: none
    &__icon
      position: absolute
      z-index: 100
      background: var(--Orange, rgba(255, 79, 18, 1))
      border: 3px solid var(--White, rgba(255, 255, 255, 1))
      width: vw(40px)
      height: vw(40px)
      display: flex
      justify-content: center
      align-items: center
      cursor: pointer
      top: vw(8px)
      right: vw(10px)
      border-top-right-radius: 3px
      border-bottom-right-radius: 3px
      border-bottom-left-radius: 3px
      @media screen and (max-width: 1000px)
        width: vwm(32px)
        height: vwm(32px)
        top: vwm(8px)
        right: vwm(10px)
      img
        width: vw(32px)
        height: vw(32px)
        @media screen and (max-width: 1000px)
          width: vwm(26px)
          height: vwm(26px)
      &-off
        position: absolute
        z-index: 100
        background: var(--Orange, rgba(255, 79, 18, 1))
        border: 3px solid var(--White, rgba(255, 255, 255, 1))
        width: vw(40px)
        height: vw(40px)
        display: flex
        justify-content: center
        align-items: center
        cursor: pointer
        top: vw(8px)
        right: vw(10px)
        border-top-right-radius: 3px
        border-bottom-right-radius: 3px
        border-bottom-left-radius: 3px
        @media screen and (max-width: 1000px)
          width: vwm(26px)
          height: vwm(26px)
        img
          width: vw(32px)
          height: vw(32px)
          @media screen and (max-width: 1000px)
          width: vwm(26px)
          height: vwm(26px)
    &__intro
        position: absolute
        width: 100%
        height: 100%
        background-color: rgba(6, 0, 31, 0.7)
        z-index: 1000
        top: 0
        padding-top: vw(215px)
        padding-left: vw(270px)
        @media screen and (max-width: 1000px)
          padding-top: vwm(148px)
          padding-left: vwm(140px)
        img
            width: vw(78px)
            height: vw(54px)
            @media screen and (max-width: 1000px)
              width: vwm(74px)
              height: vwm(54px)
    &-elements
        position: absolute
        top: 0
        width: vw(4000px)
        height: vw(1845px)
        user-select: none
        transition: opacity 300ms
        @media screen and (max-width: 1000px)
          width: vwm(4000px)
          height: vwm(1845px)
    &-el
        position: absolute
        img
            width: 100%
            height: 100%
            object-fit: cover
            transition: opacity 100ms
            &.invisible
              opacity: 0
              position: absolute
              top: 0
              left: 0
            &.active
              transform: scale(1.12)
              opacity: 1
              top: 0
              left: 0
              object-fit: contain
              position: absolute
        &--1
            width: vw(623px)
            height: vw(422px)
            left: vw(584px)
            top: vw(320px)
            @media screen and (max-width: 1000px)
              width: vwm(623px)
              height: vwm(422px)
              left: vwm(584px)
              top: vwm(320px)
        &--2
            width: vw(514px)
            height: vw(667px)
            top: vw(950px)
            right: vw(919px)
            @media screen and (max-width: 1000px)
              width: vwm(514px)
              height: vwm(667px)
              top: vwm(950px)
              right: vwm(919px)
        &--3
            width: vw(304px)
            height: vw(300px)
            top: vw(325px)
            right: vw(1338px)
            @media screen and (max-width: 1000px)
              width: vwm(304px)
              height: vwm(300px)
              top: vwm(325px)
              right: vwm(1338px)
        &--4
            width: vw(319px)
            height: vw(214px)
            top: vw(527px)
            left: vw(1154px)
            @media screen and (max-width: 1000px)
              width: vwm(319px)
              height: vwm(214px)
              top: vwm(527px)
              left: vwm(1154px)
        &--5
            width: vw(325px)
            height: vw(408px)
            top: vw(601px)
            right: vw(434px)
            @media screen and (max-width: 1000px)
              width: vwm(325px)
              height: vwm(408px)
              top: vwm(601px)
              right: vwm(434px)
        &--6
            width: vw(249px)
            height: vw(423px)
            top: vw(546px)
            right: vw(774px)
            @media screen and (max-width: 1000px)
              width: vwm(249px)
              height: vwm(423px)
              top: vwm(546px)
              right: vwm(774px)
        &--7
            width: vw(270px)
            height: vw(338px)
            top: vw(1070px)
            right: vw(190px)
            @media screen and (max-width: 1000px)
              width: vwm(270px)
              height: vwm(338px)
              top: vwm(1070px)
              right: vwm(190px)
        &--8
            width: vw(498px)
            height: vw(428px)
            top: vw(950px)
            left: vw(1398px)
            @media screen and (max-width: 1000px)
              width: vwm(498px)
              height: vwm(428px)
              top: vwm(950px)
              left: vwm(1398px)
        &--9
            width: vw(254px)
            height: vw(125px)
            top: vw(200px)
            right: vw(469px)
            @media screen and (max-width: 1000px)
              width: vwm(254px)
              height: vwm(125px)
              top: vwm(200px)
              right: vwm(469px)
        &--10
            width: vw(257px)
            height: vw(323px)
            top: vw(305px)
            left: vw(254px)
            @media screen and (max-width: 1000px)
              width: vwm(257px)
              height: vwm(323px)
              top: vwm(305px)
              left: vwm(254px)
        &--11
            width: vw(315px)
            height: vw(296px)
            top: vw(527px)
            right: vw(1194px)
            @media screen and (max-width: 1000px)
              width: vwm(315px)
              height: vwm(296px)
              top: vwm(527px)
              right: vwm(1234px)
    &-panel
        position: absolute
        z-index: 200
        bottom: 0
        width: 100%
        display: flex
        user-select: none
        flex-direction: column
        &__text
            background-color: #fff
            bottom: vw(100px)
            left: 0
            right: 0
            margin: auto
            width: auto
            padding: vw(5px) vw(10px)
            font-size: vw(15px)
            font-weight: 500
            color: var(--Orange, rgba(255, 79, 18, 1))
            border-radius: vw(10px)
            margin-bottom: vw(10px)
            @media screen and (max-width: 1000px)
              display: none
            &-mobile
              @media screen and (max-width: 1000px)
                display: block
                position: absolute
                z-index: 1000
                font-size: vwm(15px)
                line-height: vwm(22px)
                font-weight: 500
                padding: vwm(5px) vwm(10px)
                border-radius: vwm(10px)
                background-color: #fff
                color: var(--Orange, rgba(255, 79, 18, 1))
                top: vwm(10px)
                left: 25%
                right: 25%
        &__arrows
          position: absolute
          display: flex
          justify-content: space-between
          width: 100%
          bottom: 0
          &-left, &-right
            background: #fff
            width: vw(44px)
            height: vw(92px)
            cursor: pointer
            display: flex
            justify-content: center
            align-items: center
            @media screen and (max-width: 1000px)
              width: vwm(28px)
              height: vwm(72px)
            img
              width: vw(14px)
              height: vw(11px)
              @media screen and (max-width: 1000px)
                width: vwm(14px)
                height: vwm(11px)
          &-right
            border-top-right-radius: vw(20px)
            @media screen and (max-width: 1000px)
              border-top-right-radius: vwm(10px)
            img
              transform: rotate(180deg)
          &-left
            border-top-left-radius: vw(20px)
            @media screen and (max-width: 1000px)
              border-top-left-radius: vwm(10px)
        &__elements
            background: rgba(255, 255, 255, 1)
            width: 100%
            height: vw(92px)
            // border-top-right-radius: vw(20px)
            // border-top-left-radius: vw(20px)
            padding-top: vw(10px)
            padding-bottom: vw(10px)
            display: flex
            overflow: hidden
            width: calc(100% - vw(80px))
            z-index: 20
            position: relative
            margin: auto
            img
                height: vw(64px)
                margin-right: 8px
                @media screen and (max-width: 1000px)
                  height: vwm(54px)
            @media screen and (max-width: 1000px)
              width: calc(100% - vwm(50px))
              height: vwm(72px)
              padding-top: vwm(5px)
    &__bg
        width: vw(4000px)
        height: vw(1845px)
        transition: opacity 300ms
        padding-bottom: vw(150px)
        @media screen and (max-width: 1000px)
          width: vwm(4000px)
          padding-bottom: vwm(120px)
          height: vwm(1845px)
        img
            width: 100%
            height: 100%
    &__text
        position: absolute
        z-index: 100
        top: vw(282px)
        left: vw(195px)
        color: var(--White, rgba(255, 255, 255, 1))
        font-size: vw(15px)
        line-height: vw(22px)
        font-weight: 500
        @media screen and (max-width: 1000px)
          font-size: vwm(15px)
          line-height: vwm(22px)
          top: vwm(215px)
    &__button
        position: absolute
        top: vw(332px)
        left: vw(230px)
        z-index: 100
        display: flex
        @media screen and (max-width: 1000px)
          top: vwm(265px)
          left: vwm(98px)
        a
            width: vw(160px)
            height: vw(50px)
            background-color: #FF4F12
            text-align: center
            font-family: 'Tektur'
            font-weight: 500
            line-height: vw(51px)
            color: #fff
            font-size: vw(16px)
            letter-spacing: vw(1px)
            border-radius: vw(2px)
            backdrop-filter: blur(12.5px)
            filter: drop-shadow(0px 6.10394px 20.9278px rgba(0, 0, 0, 0.65))
            text-transform: uppercase
            @media screen and (max-width: 1000px)
              width: vwm(160px)
              height: vwm(50px)
              font-size: vwm(16px)
              display: flex
              justify-content: center
              align-items: center
    &__overlay
      position: absolute
      top: 0
      left: 0
      width: 100%
      height: 100%
      background-color: rgba(6, 0, 31, 0.7)
      z-index: 200
    &__finished
      color: white
      animation: fadeIn 0.8s ease
      &-text
        position: absolute
        font-size: vw(15px)
        line-height: vw(22px)
        font-weight: 500
        top: vw(250px)
        left: vw(260px)
        @media screen and (max-width: 1000px)
          font-size: vwm(15px)
          line-height: vwm(22px)
          top: vwm(170px)
          left: vwm(105px)
      &-button
        position: absolute
        display: flex
        top: vw(280px)
        left: vw(250px)
        @media screen and (max-width: 1000px)
          top: vwm(200px)
          left: vwm(90px)
        a
          width: vw(160px)
          height: vw(50px)
          background-color: #FF4F12
          text-align: center
          font-family: 'Tektur'
          font-weight: 500
          line-height: vw(51px)
          color: #fff
          font-size: vw(16px)
          letter-spacing: vw(1px)
          border-radius: vw(2px)
          backdrop-filter: blur(12.5px)
          filter: drop-shadow(0px 6.10394px 20.9278px rgba(0, 0, 0, 0.65))
          text-transform: uppercase
          border-radius: 2px
          @media screen and (max-width: 1000px)
            width: vwm(160px)
            height: vwm(50px)
            font-size: vwm(16px)
            display: flex
            justify-content: center
            align-items: center

@keyframes fadeIn
  from
    opacity: 0
  to
    opacity: 1

// .opened-image
//     -webkit-filter: drop-shadow(6px 6px 0 white) drop-shadow(-6px 6px 0 white) drop-shadow(6px -6px 0 white) drop-shadow(-6px -6px 0 white)
//     filter: drop-shadow(6px 6px 0 white) drop-shadow(-6px 6px 0 white) drop-shadow(6px -6px 0 white) drop-shadow(-6px -6px 0 white)
#scene
    width: vw(4000px)
    height: vw(1845px)
    @media screen and (max-width: 1000px)
      width: vwm(4000px)
      height: vwm(1845px)
.swiper-els
  .swiper-slide
    width: auto !important
</style>
