<template>
  <div class="wrapper-scroll" :class="{ active: active }" id="cursor">
    <div
      v-for="(image, index) in imageList"
      :key="index"
      :class="[
        'scroll',
        `scroll-${index + 1}`,
        index + 1 === Math.ceil(activeIndex) ? 'activeScreen' : '',
      ]"
      :id="[`screen-${index + 1}`]"
      :style="{
        backgroundImage: `url(${image.src})`,
        top: `${index * 996}px`,
        opacity: index === 0 ? '1' : '0',
      }"
    >
      <div
        v-if="index > activeIndex - 1 && index < activeIndex + 1"
        :data-index="index"
        :data-active="activeIndex"
        :class="['el', `el-${index + 1}`]"
        @click="$emit('open', `${index + 1}-1`)"
      ></div>
      <div
        v-if="index > activeIndex - 1 && index < activeIndex + 1"
        :class="['el', `el-${index + 1}-2`]"
        @click="$emit('open', `${index + 1}-2`)"
      ></div>
      <div
        v-if="index > activeIndex - 1 && index < activeIndex + 1"
        :class="['el', `el-${index + 1}-3`]"
        @click="$emit('open', `${index + 1}-3`)"
        style="opacity: 0"
      ></div>
      <div v-if="index === 33" class="el el-34-new" @click="$emit('open', `1-2`)">
        <span class="el-label"
          ><span>Связь <br />поколений</span></span
        >
      </div>
      <div v-if="index === 33" class="el el-34-new-2" @click="$emit('open', `1-4`)">
        <span class="el-label"
          ><span>Истории <br />связи</span></span
        >
      </div>
      <div v-if="index === 33" class="el el-34-new-3" @click="$emit('open', `1-3`)">
        <span class="el-label"
          ><span>Сила <br />технологий</span></span
        >
      </div>
    </div>
  </div>
</template>

<script>
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

export default {
  props: {
    active: {
      type: Boolean,
    },
    autoScroll: {
      type: Boolean,
    },
  },
  computed: {
    imageList() {
      const totalImages = 70;
      const images = [];
      let folder = "webp";
      for (let i = 1; i <= totalImages; i++) {
        images.push({ src: require(`@/assets/img/feed/${folder}/${i}.jpg.webp`) });
      }
      return images;
    },
  },
  data() {
    return {
      activeIndex: 0,
    };
  },
  mounted() {
    window.addEventListener("scroll", this.getActiveSlides);
    this.setupAnimation();
  },
  methods: {
    getActiveSlides() {
      const { documentElement, body } = document;
      let windowScroll = body.scrollTop || documentElement.scrollTop;
      if (windowScroll > 0) {
        let active = windowScroll / 1000;
        this.activeIndex = active;
      }
    },
    setupAnimation() {
      let isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
      let screenSize = 1000;
      let easeFunction = "linear";
      let scale = 2;
      if (isSafari) {
        screenSize = 1150;
        easeFunction = "power1.inOut";
      }
      this.imageList.forEach((_, index) => {
        let className = `.scroll-${index + 1}`;

        gsap.set(className, { xPercent: 0, yPercent: 0 });
        gsap.to(className, {
          scale: scale,
          x: 0,
          transformOrigin: "50% 50%",
          ease: easeFunction,
          scrollTrigger: {
            trigger: className,
            end: `+=${screenSize}`,
            pin: true,
            scrub: true,
            onEnter: () => {
              document.querySelector(className).style.opacity = "1";
            },
            onLeave: () => {
              document.querySelector(className).style.opacity = "0";
            },
            onEnterBack: () => {
              document.querySelector(className).style.opacity = "1";
            },
            onLeaveBack: () => {
              document.querySelector(className).style.opacity = "0";
            },
          },
        });
      });
      window.scrollTo(0, 0);
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/sass/functions.sass";

body::-webkit-scrollbar {
  display: none;
}
body {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
body {
  margin: 0;
  padding: 0;
}
.wrapper-scroll {
  width: 100%;
  height: 100vh;
  height: var(--app-height);
  //position: fixed;
  overflow: hidden;
  @media screen and (min-width: 1000px) {
    min-height: 100%;
  }
  &.active {
    height: 67000px;
  }
}
.scroll {
  position: absolute;
  width: 100%;
  will-change: transform, opacity;
  transform: translateZ(0);
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  -webkit-text-size-adjust: 100%;
  height: 100vh;
  height: var(--app-height);
  background-size: 100vw;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  pointer-events: none;
  @media screen and (max-width: 1000px) {
    background-size: auto 130vh;
  }
}
.pin-spacer {
  pointer-events: none;
}
.el {
  position: absolute;
  cursor: pointer;
  //animation: upDown 2s linear 0s infinite;
  z-index: 999990999999999999999;
  pointer-events: none;
  transition: none !important;
  @media screen and (max-width: 1000px) {
    width: vwm(80px);
    height: vwm(67px);
    background-size: contain;
    animation: none !important;
  }
}
.activeScreen .el {
  pointer-events: auto;
}

@keyframes upDown {
  0% {
    top: 0px;
  }
  50% {
    top: vw(15px);
  }
  100% {
    top: 0;
  }
}

@media screen and (max-width: 1000px) {
  @keyframes upDown {
    0% {
      top: 0px;
    }
    50% {
      top: vwm(15px);
    }
    100% {
      top: 0;
    }
  }
}
</style>

<style lang="sass">
@import '@/assets/sass/functions.sass'

// @keyframes anim1
// 	0%
// 		transform: rotate(0deg) scale(0.5) translateX(-2vw)
// 		opacity: 0
// 	100%
// 		transform: rotate(-44deg) scale(1) translateX(2vw)
// 		opacity: 1

// @keyframes anim2
// 	0%
// 		transform: rotate(-40deg) scale(0.5) translateX(-2vw)
// 		opacity: 0
// 	100%
// 		transform: rotate(22deg) scale(1) translateX(2vw)
// 		opacity: 1

// @keyframes anim3
// 	0%
// 		transform: rotate(-1deg)
// 	50%
// 		transform: rotate(1deg)
// 	100%
// 		transform: rotate(-1deg)

// @keyframes anim4
// 	0%
// 		transform: translateX(0) translateY(0)
// 		opacity: 1
// 	100%
// 		transform: translateX(1.8vw) translateY(-0.8vw)
// 		opacity: 0

// @media screen and (max-width: 1000px)
// 	@keyframes anim4
// 		0%
// 			transform: translateX(0) translateY(0)
// 			opacity: 1
// 		100%
// 			transform: translateX(6.8vw) translateY(-2.8vw)
// 			opacity: 0

// @keyframes anim5
// 	0%
// 		transform: translateX(0) translateY(0)
// 		opacity: 1
// 	100%
// 		transform: translateX(1.3vw) translateY(-1vw)
// 		opacity: 0

// @media screen and (max-width: 1000px)
// 	@keyframes anim5
// 		0%
// 			transform: translateX(0) translateY(0)
// 			opacity: 1
// 		100%
// 			transform: translateX(4.3vw) translateY(-4vw)
// 			opacity: 0

// @keyframes AnimForGlow
// 	0%
// 		transform: scale(2) rotate(0)
// 	50%
// 		transform: scale(2.8) rotate(180deg)
// 	100%
// 		transform: scale(2) rotate(360deg)

@keyframes SmallToLarge
	0%
		transform: scale(1)
	50%
		transform: scale(1.2)
	100%
		transform: scale(1)

@keyframes AnimCircle
	0%
		transform: scale(1)
		opacity: 0
	50%
		transform: scale(1.4)
		opacity: 0.4
	100%
		transform: scale(1)
		opacity: 0

@keyframes LeftToRight
	0%
		transform: translateX(0.1vw)
	50%
		transform: translateX(0.3vw)
	100%
		transform: translateX(0.1vw)
@media screen and (max-width: 1000px)
	@keyframes LeftToRight
		0%
			transform: translateX(0.4vw)
		50%
			transform: translateX(1vw)
		100%
			transform: translateX(0.4vw)

#cursor
	cursor: url('../assets/img/cursor_3.svg') 0 0, auto
.el
	position: absolute
	width: 2vw
	height: 2vw
	cursor: pointer
	cursor: url('../assets/img/cursor-active-2.svg') 0 0, auto !important
	animation: SmallToLarge 1s linear 0s infinite !important
	z-index: 99999
	left: 0
	right: 0
	bottom: 0
	top: 0
	margin: auto
	pointer-events: auto
	display: flex
	align-items: center
	justify-content: center
	@media screen and (max-width: 1000px)
		width: 8vw
		height: 8vw
	&:after
		content: ''
		border-radius: 50%
		width: 2vw
		min-width: 2vw
		height: 2vw
		background-color: #fff
		border: 2px solid #FF4F12
		box-shadow: 0px 7px 8px 0px rgba(41, 0, 0, 0.25)
		background-repeat: no-repeat
		background-position: 50% 50%
		background-size: 1.3vw
		// transition: all 300ms
		transition: none !important
		@media screen and (max-width: 1000px)
			width: 8vw
			min-width: 8vw
			height: 8vw
			background-size: 4.8vw
			border: 2px solid #FF4F12
	&:before
		content: ''
		position: absolute
		width: 2vw
		height: 2vw
		background-color: #fff
		z-index: -1
		border-radius: 50%
		animation: AnimCircle 1s linear 0s infinite !important
		@media screen and (max-width: 1000px)
			width: 8vw
			height: 8vw
	&:hover
		@media screen and (min-width: 1000px)
			&:after
				background-color: #FF4F12
				border-color: #fff
	&-label
		white-space: nowrap
		padding-left: vw(11.13px)
		display: block
		color: #fff
		font-family: 'Tektur'
		font-weight: 600
		font-size: vw(9px)
		position: absolute
		right: vw(-100px)
		span
			position: relative
			z-index: 2
			display: block
			padding-right: vw(10px)
			background-color: #FF4F12
			height: vw(24px)
			line-height: vw(24px)
		br
			display: none !important
		&::after
			background-image: url(../assets/img/el-label-desk.svg)
			content: ''
			display: block
			width: vw(59px)
			height: vw(24px)
			position: absolute
			left: 0
			top: 0
			bottom: 0
			margin: auto
		@media screen and (max-width: 1000px)
			position: absolute
			width: vwm(64.7px)
			height: vwm(28px)
			background-image: url(../assets/img/el-label-mob.svg)
			color: #fff
			font-family: 'Tektur'
			font-weight: 600
			font-size: vwm(9px)
			display: flex
			align-items: center
			justify-content: center
			right: vwm(-65px)
			background-size: contain
			background-repeat: no-repeat
			padding-left: vwm(7px)
			span
				line-height: 1.2
				height: vwm(28px)
				display: flex
				align-items: center
				justify-content: flex-sart
				background: none
			br
				display: block !important
	&-1
		left: -5vw
		top: 9vw
		transform: scale(1)
		display: none !important
		@media screen and (max-width: 1000px)
			left: -24vw
			top: 2vw
		&-2
			display: none !important
		&-3
			display: none !important
	&-2
		display: none !important
		&-2
			left: -11vw
			top: 6vw
			&:after
				background-image: url(../assets/img/info.svg)
			&:hover
				@media screen and (min-width: 1000px)
					&:after
						background-image: url(../assets/img/info-hover.svg)
			@media screen and (max-width: 1000px)
				left: -39vw
				top: 19vw
		&-3
			width: 5.5vw
			height: 1.3vw
			background-size: contain
			background-repeat: no-repeat
			background-image: url(../assets/img/clue-bg.svg)
			opacity: 1 !important
			cursor: default !important
			left: -3.7vw
			top: 6.1vw
			animation: LeftToRight 1s linear 0s infinite !important
			@media screen and (max-width: 1000px)
				width: 22vw
				height: 5vw
				left: -10vw
				top: 19.3vw
			&:after
				display: none
			&:before
				display: none
	&-3
		left: -20vw
		top: 36vw
		display: none !important
		@media screen and (max-width: 1000px)
			left: -96vw
			top: 8vw
		&-2
			display: none !important
		&-3
			display: none !important
	&-4
		left: 1vw
		top: 6vw
		display: none !important
		@media screen and (max-width: 1000px)
			left: 1vw
			top: -7vw
		&-2
			display: none !important
		&-3
			display: none !important
	&-5
		left: 2vw
		top: 12vw
		display: none !important
		@media screen and (max-width: 1000px)
			left: 2vw
			top: -14vw
		&-2
			display: none !important
		&-3
			display: none !important
	&-6
		left: 8vw
		top: 11vw
		&:after
			background-image: url(../assets/img/games.svg)
		&:hover
			@media screen and (min-width: 1000px)
				&:after
					background-image: url(../assets/img/games-hover.svg)
		@media screen and (max-width: 1000px)
			left: 34vw
			top: 39vw
		&-2
			width: 24.6vw
			height: 15.9vw
			background-color: transparent
			cursor: default !important
			border-radius: 0
			border: none
			bottom: -8.8vw
			left: -3.6vw
			z-index: 99999
			animation: none !important
			display: none !important
			@media screen and (max-width: 1000px)
				width: 85.6vw
				height: 55.9vw
				bottom: -31vw
				left: -13vw
			&:hover
				@media screen and (min-width: 1000px)
					&:after
						width: 24.6vw
						height: 15.9vw
			&:before
				content: ''
				width: 24.6vw
				height: 15.9vw
				background-color: transparent
				background-size: contain
				background-repeat: no-repeat
				background-position: 0 0
				cursor: default !important
				border-radius: 0
				border: none
				background-image: url(../assets/img/shilling.png)
				bottom: 0
				left: 0
				z-index: 99999
				position: absolute
				animation: anim4 !important
				animation-duration: 1s !important
				animation-iteration-count: infinite !important
				@media screen and (max-width: 1000px)
					width: 85.6vw
					height: 55.9vw
			&:after
				content: ''
				width: 24.6vw
				height: 15.9vw
				background-color: transparent
				background-size: contain
				background-repeat: no-repeat
				background-position: 0 0
				cursor: default !important
				border-radius: 0
				border: none
				background-image: url(../assets/img/shilling.png)
				bottom: 0
				left: 0
				z-index: 99998
				position: absolute
				animation: anim5 !important
				animation-duration: 1s !important
				animation-iteration-count: infinite !important
				@media screen and (max-width: 1000px)
					width: 85.6vw
					height: 55.9vw
		&-3
			content: ''
			width: 24.6vw
			height: 15.9vw
			background-color: transparent
			background-size: contain
			background-repeat: no-repeat
			background-position: 0 0
			cursor: default !important
			border-radius: 0
			border: none
			background-image: url(../assets/img/shilling.png)
			bottom: -8.8vw
			left: -3.6vw
			z-index: 999999
			position: absolute
			opacity: 1 !important
			animation: none !important
			display: none !important
			&:before
				display: none
			@media screen and (max-width: 1000px)
				width: 85.6vw
				height: 55.9vw
				bottom: -31vw
				left: -13vw
	&-7
		left: 50.8vw
		top: 43vw
		display: none !important
		&-2
			display: none !important
		&-3
			display: none !important
	&-8
		left: -12.2vw
		top: 15vw
		&:after
			background-image: url(../assets/img/3d.svg)
		&:hover
			@media screen and (min-width: 1000px)
				&:after
					background-image: url(../assets/img/3d-cube-hover.svg)
		@media screen and (max-width: 1000px)
			left: -49vw
			top: 61vw
		&-2
			width: 17.8vw
			height: 19.5vw
			background-color: transparent
			background-size: contain
			background-position: 0 0
			cursor: default !important
			border-radius: 0
			border: none
			background-image: url(../assets/img/first-phone.png)
			bottom: -13.8vw
			left: -14.2vw
			z-index: 99999
			animation: anim3 !important
			animation-duration: 1.5s !important
			animation-iteration-count: infinite !important
			display: none !important
			&:before
				display: none
			@media screen and (max-width: 1000px)
				width: 61vw
				height: 67vw
				bottom: -47.7vw
				left: -49vw
				animation: anim3 !important
				animation-duration: 1.5s !important
				animation-iteration-count: infinite !important
		&-3
			display: none !important
	&-9
		left: -14.4vw
		top: 32vw
		display: none !important
		@media screen and (max-width: 1000px)
			left: -44vw
			top: 10vw
		&-2
			display: none !important
		&-3
			display: none !important
	&-10
		display: none !important
		&-2
			display: none !important
		&-3
			display: none !important
	&-11
		display: none !important
		&-2
			display: none !important
		&-3
			display: none !important
	&-12
		display: none !important
		&-2
			display: none !important
		&-3
			display: none !important
	&-13
		left: 6vw
		top: 3vw
		&:after
			background-image: url(../assets/img/games.svg)
		&:hover
			@media screen and (min-width: 1000px)
				&:after
					background-image: url(../assets/img/games-hover.svg)
		@media screen and (max-width: 1000px)
			left: 22vw
			top: 11vw
		&-2
			left: 0vw
			top: 2vw
			display: none !important
			transform: scale(0.5) !important
			&:after
				background-image: url(../assets/img/gift.svg)
			&:hover
				&:after
					background-image: url(../assets/img/gift-hover.svg)
			@media screen and (max-width: 1000px)
				left: -40vw
				top: -12vw
		&-3
			display: none !important
	&-14
		left: 18vw
		top: 6vw
		display: none !important
		@media screen and (max-width: 1000px)
			left: 60vw
			top: -108vw
		&-2
			left: 0vw
			top: 6vw
			transform: scale(0.5) !important
			&:after
				background-image: url(../assets/img/gift.svg)
			&:hover
				&:after
					background-image: url(../assets/img/gift-hover.svg)
			@media screen and (max-width: 1000px)
				left: 0vw
				top: 24vw
		&-3
			display: none !important
	&-15
		left: 7.75vw
		top: 6vw
		display: none !important
		@media screen and (max-width: 1000px)
			left: 32vw
			top: -21.5vw
		&-2
			left: 0vw
			top: 12vw
			transform: scale(1) !important
			&:after
				background-image: url(../assets/img/gift.svg)
			&:hover
				&:after
					background-image: url(../assets/img/gift-hover.svg)
			@media screen and (max-width: 1000px)
				left: 0
				top: 48vw
		&-3
			display: none !important
	&-16
		left: 19vw
		top: 11vw
		&:after
			background-image: url(../assets/img/3d.svg)
		&:hover
			@media screen and (min-width: 1000px)
				&:after
					background-image: url(../assets/img/3d-cube-hover.svg)
		@media screen and (max-width: 1000px)
			left: 66vw
			top: 37vw
		&-2
			display: none !important
		&-3
			display: none !important
	&-17
		left: 31vw
		top: 12vw
		display: none !important
		@media screen and (max-width: 1000px)
			left: 128vw
			top: -86vw
		&-2
			display: none !important
		&-3
			display: none !important
	&-18
		left: 62vw
		top: 24vw
		display: none !important
		@media screen and (max-width: 1000px)
			display: flex !important
			left: 44vw
			top: -11vw
			&:after
				background-image: url(../assets/img/3d.svg)
			&:hover
				@media screen and (min-width: 1000px)
					&:after
						background-image: url(../assets/img/3d-cube-hover.svg)
		&-2
			left: 15vw
			top: -6vw
			display: none !important
			@media screen and (max-width: 1000px)
				left: 60vw
				top: -55vw
		&-3
			display: none !important
	&-19
		left: 25vw
		top: 2vw
		&:after
			background-image: url(../assets/img/3d.svg)
		&:hover
			@media screen and (min-width: 1000px)
				&:after
					background-image: url(../assets/img/3d-cube-hover.svg)
		@media screen and (max-width: 1000px)
			left: 82vw
			top: -9vw
			display: none !important
		&-2
			left: 4vw
			top: -9vw
			display: none !important
			@media screen and (max-width: 1000px)
				left: 16vw
				top: -71vw
		&-3
			display: none !important
	&-20
		left: 15vw
		top: -5vw
		&:after
			background-image: url(../assets/img/3d.svg)
		&:hover
			@media screen and (min-width: 1000px)
				&:after
					background-image: url(../assets/img/3d-cube-hover.svg)
		@media screen and (max-width: 1000px)
			left: 52vw
			top: -16vw
		&-2
			display: none !important
		&-3
			display: none !important
	&-21
		left: 30vw
		top: -10vw
		transform: scale(2) !important
		&:after
			background-image: url(../assets/img/3d.svg)
		&:hover
			@media screen and (min-width: 1000px)
				&:after
					background-image: url(../assets/img/3d-cube-hover.svg)
		@media screen and (max-width: 1000px)
			display: none
		&-2
			display: none !important
		&-3
			display: none !important
	&-22
		left: 16vw
		top: 8vw
		&:after
			background-image: url(../assets/img/3d.svg)
		&:hover
			@media screen and (min-width: 1000px)
				&:after
					background-image: url(../assets/img/3d-cube-hover.svg)
		@media screen and (max-width: 1000px)
			left: 57vw
			top: 27vw
		&-2
			left: 43.8vw
			top: 19vw
			opacity: 1 !important
			@media screen and (max-width: 1000px)
				display: none !important
		&-2
			display: none !important
		&-3
			display: none !important
	&-23
		left: 32vw
		top: 16vw
		transform: scale(2) !important
		&:after
			background-image: url(../assets/img/3d.svg)
		&:hover
			@media screen and (min-width: 1000px)
				&:after
					background-image: url(../assets/img/3d-cube-hover.svg)
		@media screen and (max-width: 1000px)
			display: none !important
		&-2
			left: -3vw
			top: -5vw
			display: none !important
			@media screen and (max-width: 1000px)
				left: -11vw
				top: -27vw
		&-3
			display: none !important
	&-24
		left: -8vw
		top: -16vw
		&:after
			background-image: url(../assets/img/games.svg)
		&:hover
			@media screen and (min-width: 1000px)
				&:after
					background-image: url(../assets/img/games-hover.svg)
		@media screen and (max-width: 1000px)
			left: -23vw
			top: -52vw
		&-2
			display: none !important
		&-3
			display: none !important
	&-25
		left: 0vw
		top: 10vw
		display: none !important
		@media screen and (max-width: 1000px)
			top: 0
		&-2
			display: none !important
		&-3
			display: none !important
	&-26
		left: -8vw
		top: 11vw
		&:after
			background-image: url(../assets/img/3d.svg)
		&:hover
			@media screen and (min-width: 1000px)
				&:after
					background-image: url(../assets/img/3d-cube-hover.svg)
		@media screen and (max-width: 1000px)
			top: 36vw
			left: -29vw
		&-2
			display: none !important
		&-3
			display: none !important
	&-27
		left: 0vw
		top: 40vw
		display: none !important
		&-2
			display: none !important
		&-3
			display: none !important
	&-28
		left: -10vw
		top: 2vw
		display: none !important
		&-2
			display: none !important
			@media screen and (max-width: 1000px)
				display: flex !important
				left: -42vw
				top: 8vw
				&:after
					background-image: url(../assets/img/info.svg)
				&:hover
					@media screen and (min-width: 1000px)
						&:after
							background-image: url(../assets/img/info-hover.svg)
		&-3
			display: none !important
	&-29
		left: -23vw
		top: 5vw
		&:after
			background-image: url(../assets/img/info.svg)
		&:hover
			@media screen and (min-width: 1000px)
				&:after
					background-image: url(../assets/img/info-hover.svg)
		@media screen and (max-width: 1000px)
			display: none !important
		&-2
			left: -4vw
			top: -10vw
			display: none !important
			@media screen and (max-width: 1000px)
				top: -60vw
				left: -15vw
		&-3
			display: none !important
	&-30
		left: 10vw
		top: -13vw
		&:after
			background-image: url(../assets/img/info.svg)
		&:hover
			@media screen and (min-width: 1000px)
				&:after
					background-image: url(../assets/img/info-hover.svg)
		@media screen and (max-width: 1000px)
			top: -48vw
			left: 34vw
		&-2
			display: none !important
		&-3
			display: none !important
	&-31
		display: none !important
		&-2
			display: none !important
		&-3
			display: none !important
	&-32
		left: 2vw
		top: 7vw
		display: none !important
		@media screen and (max-width: 1000px)
			top: 0vw
			left: 10vw
		&-2
			display: none !important
		&-3
			display: none !important
	&-33
		left: 6vw
		top: 5vw
		display: none !important
		@media screen and (max-width: 1000px)
			top: 0vw
			left: 20vw
		&-2
			display: none !important
		&-3
			display: none !important
	&-34
		left: 8vw
		top: 10vw
		&:after
			background-image: url(../assets/img/info.svg)
		&:hover
			@media screen and (min-width: 1000px)
				&:after
					background-image: url(../assets/img/info-hover.svg)
		@media screen and (max-width: 1000px)
			left: 29vw
			top: 36vw
		&-2
			display: none!important
		&-new
			left: -20vw
			top: -5vw
			@media screen and (max-width: 1000px)
				left: -30vw
				top: -80vw
			&:after
				background-image: url(../assets/img/pin-01.svg)
				transition: none !important
			&:hover
				&:after
					@media screen and (min-width: 1000px)
						background-image: url(../assets/img/pin-01-invert.svg) !important
		&-new-2
			left: 18vw
			top: -12vw
			@media screen and (max-width: 1000px)
				left: -53vw
				top: 51vw
			&:after
				background-image: url(../assets/img/book.svg)
				transition: none !important
			&:hover
				&:after
					@media screen and (min-width: 1000px)
						background-image: url(../assets/img/book-hover.svg) !important
		&-new-3
			left: 16vw
			top: 2vw
			@media screen and (max-width: 1000px)
				left: 29vw
				top: 0vw
			&:after
				background-image: url(../assets/img/user.svg)
				transition: none !important
			&:hover
				&:after
					@media screen and (min-width: 1000px)
						background-image: url(../assets/img/user-hover.svg) !important
		&-3
			display: none !important
	&-35
		left: 4vw
		top: 8.5vw
		transform: scale(0.5) !important
		&:after
			background-image: url(../assets/img/info.svg)
		&:hover
			@media screen and (min-width: 1000px)
				&:after
					background-image: url(../assets/img/info-hover.svg)
		@media screen and (max-width: 1000px)
			left: 0vw
			top: 0vw
			display: none
		&-2
			left: -0.9vw
			top: -9.5vw
			transform: scale(0.5) !important
			&:after
				background-image: url(../assets/img/games.svg)
			&:hover
				@media screen and (min-width: 1000px)
					&:after
						background-image: url(../assets/img/games-hover.svg)
			@media screen and (max-width: 1000px)
				display: none
		&-3
			display: none !important
	&-36
		left: 8vw
		top: 17vw
		&:after
			background-image: url(../assets/img/info.svg)
		&:hover
			@media screen and (min-width: 1000px)
				&:after
					background-image: url(../assets/img/info-hover.svg)
		@media screen and (max-width: 1000px)
			left: 27vw
			top: 64vw
		&-2
			left: -1.8vw
			top: -19vw
			&:after
				background-image: url(../assets/img/games.svg)
			&:hover
				@media screen and (min-width: 1000px)
					&:after
						background-image: url(../assets/img/games-hover.svg)
			@media screen and (max-width: 1000px)
				left: -6vw
				top: -65vw
		&-3
			display: none !important
	&-37
		left: -3.5vw
		top: -6.5vw
		transform: scale(0.5) !important
		&:after
			background-image: url(../assets/img/info.svg)
		&:hover
			@media screen and (min-width: 1000px)
				&:after
					background-image: url(../assets/img/info-hover.svg)
		@media screen and (max-width: 1000px)
			top: -60vw
			left: 2vw
		&-2
			display: none !important
		&-3
			display: none !important
	&-38
		left: -7vw
		top: -13vw
		&:after
			background-image: url(../assets/img/info.svg)
		&:hover
			@media screen and (min-width: 1000px)
				&:after
					background-image: url(../assets/img/info-hover.svg)
		@media screen and (max-width: 1000px)
			left: -27vw
			top: -42vw
		&-2
			display: none !important
		&-3
			display: none !important
	&-39
		display: none !important
		&-2
			display: none !important
		&-3
			display: none !important
	&-40
		display: none !important
		&-2
			display: none !important
		&-3
			display: none !important
	&-41
		display: none !important
		&-2
			display: none !important
		&-3
			display: none !important
	&-42
		left: 2vw
		top: 6vw
		display: none !important
		@media screen and (max-width: 1000px)
			left: 8vw
			top: 0
		&-2
			display: none !important
		&-3
			display: none !important
	&-43
		left: -2.5vw
		top: 11vw
		transform: scale(0.5) !important
		&:after
			background-image: url(../assets/img/games.svg)
		&:hover
			@media screen and (min-width: 1000px)
				&:after
					background-image: url(../assets/img/games-hover.svg)
		@media screen and (max-width: 1000px)
			left: 16vw
			top: 12vw
		&-2
			display: none !important
		&-3
			display: none !important
	&-44
		left: -5vw
		top: 22vw
		&:after
			background-image: url(../assets/img/games.svg)
		&:hover
			@media screen and (min-width: 1000px)
				&:after
					background-image: url(../assets/img/games-hover.svg)
		@media screen and (max-width: 1000px)
			left: -18vw
			top: 75vw
		&-2
			display: none !important
		&-3
			display: none !important
	&-45
		left: 9vw
		top: -1vw
		display: none !important
		@media screen and (max-width: 1000px)
			left: 40vw
			top: -40vw
		&-2
			left: -3vw
			top: 3vw
			display: none !important
			@media screen and (max-width: 1000px)
				left: -10vw
				top: -10vw
		&-3
			display: none !important
	&-46
		left: 19vw
		top: 3vw
		&:after
			background-image: url(../assets/img/info.svg)
		&:hover
			@media screen and (min-width: 1000px)
				&:after
					background-image: url(../assets/img/info-hover.svg)
		@media screen and (max-width: 1000px)
			left: 65vw
			top: 14vw
		&-2
			left:-6vw
			top: 6vw
			display: none !important
			@media screen and (max-width: 1000px)
				left: -20vw
				top: -20vw
		&-3
			display: none !important
	&-47
		left: 36vw
		top: -4vw
		display: none !important
		@media screen and (max-width: 1000px)
			display: flex !important
			left: -54vw
			top: 32vw
			&:after
				background-image: url(../assets/img/games.svg)
			&:hover
				@media screen and (min-width: 1000px)
					&:after
						background-image: url(../assets/img/games-hover.svg)
		&-2
			left: -15vw
			top: 9vw
			&:after
				background-image: url(../assets/img/games.svg)
			&:hover
				@media screen and (min-width: 1000px)
					&:after
						background-image: url(../assets/img/games-hover.svg)
			@media screen and (max-width: 1000px)
				display: none !important
		&-3
			display: none !important
	&-48
		left: 0vw
		top: 5vw
		display: none !important
		@media screen and (max-width: 1000px)
			top: -10vw
		&-2
			display: none !important
		&-3
			display: none !important
	&-49
		left: 0vw
		top: 10vw
		display: none !important
		@media screen and (max-width: 1000px)
			top: -20vw
		&-2
			display: none !important
		&-3
			display: none !important
	&-50
		left: 0vw
		top: 20vw
		display: none !important
		@media screen and (max-width: 1000px)
			top: -40vw
		&-2
			display: none !important
		&-3
			display: none !important
	&-51
		left: 2vw
		top: 3vw
		&:after
			background-image: url(../assets/img/info.svg)
		&:hover
			@media screen and (min-width: 1000px)
				&:after
					background-image: url(../assets/img/info-hover.svg)
		@media screen and (max-width: 1000px)
			display: none !important
		&-2
			display: none !important
		&-3
			display: none !important
	&-52
		left: 0vw
		top: 35vw
		display: none !important
		@media screen and (max-width: 1000px)
			display: flex !important
			left: 6vw
			top: 26vw
			&:after
				background-image: url(../assets/img/info.svg)
			&:hover
				@media screen and (min-width: 1000px)
					&:after
						background-image: url(../assets/img/info-hover.svg)
		&-2
			display: none !important
		&-3
			display: none !important
	&-53
		left: -4vw
		top: 2vw
		display: none !important
		@media screen and (max-width: 1000px)
			left: -16vw
			top: -20vw
		&-2
			display: none !important
		&-3
			display: none !important
	&-54
		left: -8vw
		top: 4vw
		display: none !important
		@media screen and (max-width: 1000px)
			left: -32vw
			top: -40vw
		&-2
			display: none !important
		&-3
			display: none !important
	&-55
		left: -14vw
		top: 2vw
		&:after
				background-image: url(../assets/img/games.svg)
		&:hover
			@media screen and (min-width: 1000px)
				&:after
					background-image: url(../assets/img/games-hover.svg)
		@media screen and (max-width: 1000px)
			left: -49vw
			top: 8vw
		&-2
			left: 3vw
			top: -10vw
			&:after
				background-image: url(../assets/img/info.svg)
			&:hover
				@media screen and (min-width: 1000px)
					&:after
						background-image: url(../assets/img/info-hover.svg)
			@media screen and (max-width: 1000px)
				left: 9vw
				top: -38vw
		&-3
			display: none !important
	&-56
		left: 0vw
		top: -8vw
		display: none !important
		@media screen and (max-width: 1000px)
			top: -60vw
		&-2
			display: none !important
		&-3
			display: none !important
	&-57
		left: -1vw
		top: 10.5vw
		transform: scale(0.5) !important
		&:after
			background-image: url(../assets/img/games.svg)
		&:hover
			@media screen and (min-width: 1000px)
				&:after
					background-image: url(../assets/img/games-hover.svg)
		@media screen and (max-width: 1000px)
			left: -25vw
			top: 20vw
		&-2
			display: none !important
		&-3
			display: none !important
	&-58
		left: -2vw
		top: 21vw
		&:after
			background-image: url(../assets/img/games.svg)
		&:hover
			@media screen and (min-width: 1000px)
				&:after
					background-image: url(../assets/img/games-hover.svg)
		@media screen and (max-width: 1000px)
			left: -7vw
			top: 78vw
		&-2
			display: none !important
		&-3
			display: none !important
	&-59
		left: -4vw
		top: 8vw
		display: none !important
		&-2
			display: none !important
		&-3
			display: none !important
	&-60
		left: -13vw
		top: 8vw
		&:after
			background-image: url(../assets/img/games.svg)
		&:hover
			@media screen and (min-width: 1000px)
				&:after
					background-image: url(../assets/img/games-hover.svg)
		@media screen and (max-width: 1000px)
			left: -43vw
			top: 28vw
		&-2
			display: none !important
		&-3
			display: none !important
	&-61
		left: 0.5vw
		top: 10.5vw
		transform: scale(0.5) !important
		&:after
			background-image: url(../assets/img/info.svg)
		&:hover
			@media screen and (min-width: 1000px)
				&:after
					background-image: url(../assets/img/info-hover.svg)
		@media screen and (max-width: 1000px)
			left: -0.5vw
			top: 35vw
		&-2
			display: none !important
		&-3
			display: none !important
	&-62
		left: 1vw
		top: 21vw
		&:after
			background-image: url(../assets/img/info.svg)
		&:hover
			@media screen and (min-width: 1000px)
				&:after
					background-image: url(../assets/img/info-hover.svg)
		@media screen and (max-width: 1000px)
			left: 1vw
			top: 70vw
		&-2
			left: -8vw
			top: 1vw
			transform: scale(0.5) !important
			&:after
				background-image: url(../assets/img/3d.svg)
			&:hover
				@media screen and (min-width: 1000px)
					&:after
						background-image: url(../assets/img/3d-cube-hover.svg)
			@media screen and (max-width: 1000px)
				left: -27vw
				top: 4.5vw
		&-3
			display: none !important
	&-63
		left: 0vw
		top: 10vw
		transform: scale(0.5) !important
		&:after
			background-image: url(../assets/img/info.svg)
		&:hover
			@media screen and (min-width: 1000px)
				&:after
					background-image: url(../assets/img/info-hover.svg)
		@media screen and (max-width: 1000px)
			left: 6.5vw
			top: 45vw
		&-2
			left: -16vw
			top: 2vw
			&:after
				background-image: url(../assets/img/3d.svg)
			&:hover
				@media screen and (min-width: 1000px)
					&:after
						background-image: url(../assets/img/3d-cube-hover.svg)
			@media screen and (max-width: 1000px)
				left: -54vw
				top: 9vw
		&-3
			left: 0vw
			top: 20vw
			display: none !important
			@media screen and (max-width: 1000px)
				left: 31vw
				top: 12vw
				display: flex !important
				opacity: 1 !important
				transform: scale(0.5) !important
				&:after
					background-image: url(../assets/img/games.svg)
				&:hover
					@media screen and (min-width: 1000px)
						&:after
							background-image: url(../assets/img/games-hover.svg)
	&-64
		left: 0vw
		top: 20vw
		display: none !important
		@media screen and (max-width: 1000px)
			left: 13vw
			top: 90vw
			display: flex !important
			&:after
				background-image: url(../assets/img/info.svg)
			&:hover
				@media screen and (min-width: 1000px)
					&:after
						background-image: url(../assets/img/info-hover.svg)
		&-2
			left: 0vw
			top: 20vw
			&:after
				background-image: url(../assets/img/info.svg)
			&:hover
				@media screen and (min-width: 1000px)
					&:after
						background-image: url(../assets/img/info-hover.svg)
			@media screen and (max-width: 1000px)
				left: 43vw
				top: 29vw
				display: none !important
		&-3
			left: 0vw
			top: 20vw
			display: none !important
			@media screen and (max-width: 1000px)
				left: 62vw
				top: 24vw
				display: flex !important
				opacity: 1 !important
				transform: scale(1) !important
				&:after
					background-image: url(../assets/img/games.svg)
				&:hover
					@media screen and (min-width: 1000px)
						&:after
							background-image: url(../assets/img/games-hover.svg)
	&-65
		left: 28vw
		top: 16vw
		&:after
			background-image: url(../assets/img/games.svg)
		&:hover
			@media screen and (min-width: 1000px)
				&:after
					background-image: url(../assets/img/games-hover.svg)
		@media screen and (max-width: 1000px)
			left: 80vw
			top: 60vw
		&-2
			display: none !important
		&-3
			display: none !important
	&-66
		left: -9vw
		top: 3vw
		display: none !important
		&-2
			left: 9vw
			top: -4vw
			display: none !important
		&-3
			display: none !important
	&-67
		display: none !important
		@media screen and (max-width: 1000px)
			width: 10vw
			left: 15vw
			top: 4vw
			height: 10vw
			background-image: url(../assets/img/cinema-pin.png)
			background-repeat: no-repeat
			background-size: contain
			background-position: 50% 50%
			display: flex !important
			&:after
				display: none
			&:before
				display: none
		&-2
			display: none !important
			@media screen and (max-width: 1000px)
				width: 10vw
				left: -15vw
				top: -5vw
				height: 10vw
				background-image: url(../assets/img/pin-smart.png)
				background-repeat: no-repeat
				background-size: contain
				background-position: 50% 50%
				display: flex !important
				&:after
					display: none
				&:before
					display: none
		&-3
			display: none !important
			@media screen and (max-width: 1000px)
				width: 10vw
				left: -11vw
				top: 20vw
				height: 10vw
				background-image: url(../assets/img/pin-game.png)
				background-repeat: no-repeat
				background-size: contain
				background-position: 50% 50%
				opacity: 1 !important
				display: flex !important
				&:after
					display: none
				&:before
					display: none
	&-68
		width: 4vw
		left: 8vw
		top: 3vw
		height: 4vw
		background-image: url(../assets/img/cinema-pin.png)
		background-repeat: no-repeat
		background-size: contain
		background-position: 50% 50%
		@media screen and (max-width: 1000px)
			width: 20vw
			height: 20vw
			left: 30vw
			top: 8vw
		&:hover
			background-image: url(../assets/img/cinema-pin-hover.png)
		&:after
			display: none
		&:before
			display: none
		&-2
			width: 4vw
			left: -8vw
			top: -6vw
			height: 4vw
			background-image: url(../assets/img/pin-smart.png)
			background-repeat: no-repeat
			background-size: contain
			background-position: 50% 50%
			@media screen and (max-width: 1000px)
				width: 20vw
				height: 20vw
				left: -30vw
				top: -10vw
			&:hover
				background-image: url(../assets/img/pin-smart-hover.png)
			&:after
				display: none
			&:before
				display: none
		&-3
			width: 4vw
			left: -4vw
			top: 10vw
			height: 4vw
			background-image: url(../assets/img/pin-game.png)
			background-repeat: no-repeat
			background-size: contain
			opacity: 1 !important
			background-position: 50% 50%
			@media screen and (max-width: 1000px)
				width: 20vw
				height: 20vw
				left: -22vw
				top: 40vw
			&:hover
				background-image: url(../assets/img/pin-game-hover.png)
			&:after
				display: none
			&:before
				display: none
	&-69
		width: 8vw
		left: 16vw
		top: 6vw
		height: 8vw
		background-image: url(../assets/img/cinema-pin.png)
		background-repeat: no-repeat
		background-size: contain
		background-position: 50% 50%
		@media screen and (max-width: 1000px)
			display: none
		&:hover
			background-image: url(../assets/img/cinema-pin-hover.png)
		&:after
			display: none
		&:before
			display: none
		&-2
			@media screen and (min-width: 1000px)
				width: 8vw
				left: -16vw
				top: -12vw
				height: 8vw
				background-image: url(../assets/img/pin-smart.png)
				background-repeat: no-repeat
				background-size: contain
				background-position: 50% 50%
				&:hover
					background-image: url(../assets/img/pin-smart-hover.png)
				&:after
					display: none
				&:before
					display: none
			@media screen and (max-width: 1000px)
				left: -34vw
				top: 29vw
				display: flex !important
				&:after
					background-image: url(../assets/img/info.svg)
				&:hover
					@media screen and (min-width: 1000px)
						&:after
							background-image: url(../assets/img/info-hover.svg)
		&-3
			@media screen and (min-width: 1000px)
				width: 8vw
				left: -8vw
				top: 20vw
				height: 8vw
				background-image: url(../assets/img/pin-game.png)
				background-repeat: no-repeat
				background-size: contain
				background-position: 50% 50%
				opacity: 1 !important
				&:hover
					background-image: url(../assets/img/pin-game-hover.png)
				&:after
					display: none
				&:before
					display: none
			@media screen and (max-width: 1000px)
				left: 43vw
				top: -14vw
				display: flex !important
				opacity: 1 !important
				&:after
					background-image: url(../assets/img/info.svg)
				&:hover
					@media screen and (min-width: 1000px)
						&:after
							background-image: url(../assets/img/info-hover.svg)
	&-70
		left: -21vw
		top: 17vw
		@media screen and (max-width: 1000px)
			left: -34vw
			top: 29vw
			display: flex !important
			&:after
				background-image: url(../assets/img/info.svg)
			&:hover
				@media screen and (min-width: 1000px)
					&:after
						background-image: url(../assets/img/info-hover.svg)
		&:after
			background-image: url(../assets/img/info.svg)
		&:hover
			@media screen and (min-width: 1000px)
				&:after
					background-image: url(../assets/img/info-hover.svg)
		@media screen and (max-width: 1000px)
			left: -80vw
			top: 6vw
			display: none !important
		&-2
			left: 22vw
			top: -6vw
			@media screen and (max-width: 1000px)
				left: 43vw
				top: -14vw
				display: flex !important
				&:after
					background-image: url(../assets/img/info.svg)
				&:hover
					@media screen and (min-width: 1000px)
						&:after
							background-image: url(../assets/img/info-hover.svg)
			&:after
				background-image: url(../assets/img/info.svg)
			&:hover
				@media screen and (min-width: 1000px)
					&:after
						background-image: url(../assets/img/info-hover.svg)
			@media screen and (max-width: 1000px)
				left: 80vw
				top: -80vw
				display: none !important
#screen-60
	z-index: 100
#screen-61
	z-index: 90
</style>
